import { ErrorMessage } from '../../stores/applicationForm.store';

export const createForbiddenErrorMessage = (stacktrace: any): ErrorMessage => {
  const errorMessage: ErrorMessage = {
    stackTrace: stacktrace,
    displayText: 'forbidden to access resource',
  };
  return errorMessage;
};

export const createUnauthorizedErrorMessage = (stacktrace: any): ErrorMessage => {
  const errorMessage: ErrorMessage = {
    stackTrace: stacktrace,
    displayText: 'unauthorized',
  };
  return errorMessage;
};
