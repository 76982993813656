import styled from '@emotion/styled';
import React from 'react';
import DotLoader from '../dotLoader/DotLoader';

const LoadingMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 4rem 3rem;

  & h2,
  & h3 {
    margin: 0;
    text-align: center;
  }
`;

interface IProps {
  message: string;
}

const LongLoader: React.FC<IProps> = ({ message }) => (
  <LoadingMessage>
    <DotLoader />
    <h2>{message}</h2>
    <h6 style={{ textAlign: 'center' }}>
      This may take a few seconds, <strong>please do not close this window.</strong>
    </h6>
  </LoadingMessage>
);

export default LongLoader;
