/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useCallback } from 'react';
import styled from '@emotion/styled';
import { FieldProps, useFormikContext } from 'formik';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { Checkbox } from '../formElements/FormElements';

const RadioButtonCardWrapper = styled.div`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin-bottom: 1rem;
  font-family: ${fontFamilies.interRegular};

  width: 100%;
  height: 4rem;
  border-radius: 1rem;

  box-shadow: 0px 2px 8px 0px ${colors.lightGrey};

  :hover {
    box-shadow: 0px 2px 8px 0px ${colors.darkMidGray};
  }

  &.selected {
    border: 1px;
    border-color: ${colors.koalafiBlue};
    border-style: solid;
  }
`;

const Card = styled.div`
  flex: 3;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${fontFamilies.interBold};

  height: 100%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;

  color: ${colors.darkBlue};
  background: ${colors.lightBlue};

  &.selected {
    color: ${colors.white};
    background: ${colors.koalafiBlue};
  }
`;

const CheckboxWrapper = styled.label`
  flex: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  font-family: ${fontFamilies.interRegular};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1rem;
  color: ${colors.darkBlue};
`;

const CheckWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(1rem + 4px);
  height: calc(1rem + 4px);
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-left: 2px;
  padding-right: 2px;

  &:hover input {
    border-width: 2px;
  }
`;

const CheckLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    color: ${colors.koalafiBlue};
  }
`;

interface RadioButtonCardProps {
  id: string;
  radioValue: string;
  radioLabel: ReactNode;
  cardContent: ReactNode;
  changeHandler: () => void;
}

const RadioButtonCard: React.FC<RadioButtonCardProps & FieldProps> = (props) => {
  const { setFieldValue } = useFormikContext();
  const onClick = useCallback(() => {
    setFieldValue(props.field.name, props.radioValue);
    props.changeHandler();
  }, [setFieldValue, props.field.name, props.field.onChange]);
  return (
    <RadioButtonCardWrapper className={props.radioValue === props.field.value ? 'selected' : ''} onClick={onClick}>
      <CheckboxWrapper>
        <CheckWrap>
          <Checkbox
            id={props.id}
            type={'radio'}
            value={props.radioValue}
            checked={props.radioValue === props.field.value}
            name={props.field.name}
            size={1}
            style={{ margin: 0 }}
            onChange={props.field.onChange}
          />
        </CheckWrap>
        <CheckLabel className={props.radioValue === props.field.value ? 'selected' : ''}>{props.radioLabel}</CheckLabel>
      </CheckboxWrapper>
      <Card className={props.radioValue === props.field.value ? 'selected' : ''}>{props.cardContent}</Card>
    </RadioButtonCardWrapper>
  );
};

export default RadioButtonCard;
