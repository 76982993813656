import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ClockImage } from '../../assets/Clock.svg';
import Tooltip from '../../components/tooltipV2/Tooltip';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';
import Table from '../../components/table';

type EarlyPurchaseContentProps = {
  itemsWithTax: string;
  ipWithTax: string;
  cashPlusPrice: string;
  cashPlusFee: string;
  cashPlusTerm: { singular: string; plural: string };
  itemsTotal: string;
  salesTax: string;
  isVirtualCard: boolean;
};

const EarlyPurchaseContainer = styled.div`
  margin-top: 1rem;
  font-family: ${fontFamilies.interLight};
  border-radius: 4px;
  border: 1px solid #dee1e1;
  padding: 1rem;
`;
const EarlyPurchaseHeader = styled.div`
  color: ${colors.darkBlue};
  font-family: ${fontFamilies.gilroyBold};
  font-size: 1.25rem;
  line-height: 125%;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;

const SubtextStyle = styled.div`
  color: ${colors.darkBlue};
  font-family: ${fontFamilies.interLight};
  font-size: 0.8rem;
  line-height: 125%;
  text-align: left;
  margin-bottom: 10px;
  & strong,
  & a {
    font-family: ${fontFamilies.interSemiBold};
  }
`;

const Spacer = styled.div`
  height: 1rem;
`;

const doNotQualifyTooltip = (cpt: string) => (
  <div>
    If you do not qualify for the {cpt} purchase option due to missed rental payments or other reasons, you may exercise
    a separate early purchase option and gain ownership of your leased items by making an additional payment equal to a
    discounted amount. To complete the early purchase option, all outstanding costs, taxes, applicable fees and charges
    must be paid. Discounted rate may vary by state. See your lease agreement for details.
  </div>
);
const retailPriceTooltip = (
  <div>This is the cash purchase price of the items you are leasing plus shipping, and excluding tax.</div>
);
const salesTaxTooltip = <div>This is the sales tax associated with your purchase amount.</div>;
const initialPaymentTooltip = (
  <div>
    The initial payment (including tax) is in addition to your lease amount and any taxes and is counted as payment 1 in
    your schedule.
  </div>
);

const getLabel = (labelText: string, tooltipID: string, tooltipContent: any) => {
  return (
    <div key={tooltipID}>
      {labelText} <Tooltip id={tooltipID} tooltipContent={tooltipContent} inline={true} size="12px" />
    </div>
  );
};

const EarlyPurchaseContent: React.FC<EarlyPurchaseContentProps> = ({
  ipWithTax,
  cashPlusPrice,
  cashPlusFee,
  salesTax,
  itemsTotal,
  itemsWithTax,
  cashPlusTerm,
  isVirtualCard,
}) => {
  // Tooltips
  const earlyPurchaseFeeTooltip = (
    <div>
      You will pay an early purchase fee if you exercise your early purchase option within the first{' '}
      {cashPlusTerm.plural} after delivery.
    </div>
  );
  const earlyPurchaseAmountTooltip = (
    <div>
      If you pay in full within {cashPlusTerm.plural} of opening your lease without missing any payments, you pay only{' '}
      {cashPlusPrice}. This includes the total purchase amount including taxes ({itemsWithTax}), your initial payment (
      {ipWithTax}), and applicable fees ({cashPlusFee}).
    </div>
  );

  const tableContent = [
    ...(!isVirtualCard
      ? [{ label: getLabel('Retail price', 'ebo-retailPrice', retailPriceTooltip), value: itemsTotal }]
      : []),
    ...(!isVirtualCard ? [{ label: getLabel('Sales tax', 'ebo-salesTax', salesTaxTooltip), value: salesTax }] : []),
    { label: getLabel('Initial payment', 'ebo-initialPayment', initialPaymentTooltip), value: ipWithTax },
    { label: getLabel('Early purchase fee', 'ebo-earlyPurchaseFee', earlyPurchaseFeeTooltip), value: cashPlusFee },
    {
      label: getLabel('Early purchase amount', 'ebo-earlyPurchaseAmount', earlyPurchaseAmountTooltip),
      value: cashPlusPrice,
      makeBold: true,
    },
  ];
  return (
    <EarlyPurchaseContainer>
      <ClockImage />
      <EarlyPurchaseHeader>Early Purchase Options</EarlyPurchaseHeader>
      <SubtextStyle>
        If you pay off <strong>within {cashPlusTerm.plural}</strong> from when your items ship, you only pay the retail
        price plus any financing fees. Call 844-937-8275 after you get your items to set up additional payments to
        execute your early purchase option.
        <Tooltip id="ebo-subheader-tooltip" tooltipContent={earlyPurchaseAmountTooltip} inline={true} size="12px" />
      </SubtextStyle>
      <Table name="early-purchase-table" rows={tableContent} />
      <Spacer />
      <SubtextStyle>
        <strong>If you do not qualify for or choose not to execute the {cashPlusTerm.plural} option</strong>, you can
        still pay off your lease early to receive a significant discount on lease-to-own financing costs.
        <Tooltip
          id="earlyBuyout"
          tooltipContent={doNotQualifyTooltip(cashPlusTerm.singular)}
          inline={true}
          size="12px"
        />
      </SubtextStyle>
    </EarlyPurchaseContainer>
  );
};

export default EarlyPurchaseContent;
