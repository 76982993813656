import orderService from './order.service';
import authService from './auth.service';
import applicationFormService from './applicationForm.service';
import loansService from './loan.service';
import featureFlagService from './featureFlag.service';
import virtualCardService from './virtualCard.service';

const services = {
  orderService,
  authService,
  applicationFormService,
  loansService,
  featureFlagService,
  virtualCardService,
};

export default services;
