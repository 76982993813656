import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';

const LinkStyle = styled('div')`
  display: inline-block;
  font-family: ${fontFamilies.interSemiBold};
  font-size: 0.75rem;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.koalafiBlue};
`;

export interface IProps {
  id?: string;
  onClick?: (e: any) => any;
  linkText: string;
  size?: 'large' | 'small';
  underline?: boolean;
  children?: ReactNode;
}

/**
 * WCF-styled wrapper for react-router Link
 */
const Link: React.FC<IProps> = (props) => {
  const { children, ...rest } = props;

  return <LinkStyle {...rest}>{props.linkText}</LinkStyle>;
};

export default Link;
