import { assign, createMachine } from 'xstate';
import { NavigationSections } from '../constants';
import { transferToRapid } from '../helpers/cookies/unified.helper';
import {
  LongFormEvents,
  LongFormMachineContext,
  LongFormMachineEvents,
  LongFormMachineGuards,
  LongFormMachineStates,
  LongFormStates,
} from './types/longFormMachine.types';

export default createMachine<LongFormMachineContext, LongFormMachineEvents, LongFormMachineStates>(
  {
    id: 'Draft',
    initial: LongFormStates.Entry,
    context: {
      order: null,
      transitionedFrom: null,
      highestSupportedApplicationMilestone: null,
      hardDeclineError: null,
      skipToPage: null,
    },
    states: {
      // ephemeral state, used exclusively for immediate switching
      [LongFormStates.Entry]: {
        always: [
          {
            target: LongFormStates.PersonalInformation,
            cond: 'NoApplication',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.PersonalInformation,
            cond: 'NoAppStep',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.PersonalInformation,
            cond: 'AppStepPersonalInformation',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.ContactInformation,
            cond: 'AppStepContactData',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.IncomeSource,
            cond: 'AppStepIncomeSource',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.BankingInformation,
            cond: 'AppStepBankingInformation',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.DebitCardInformation,
            cond: 'AppStepDebitCardInformation',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.TransferToRapid,
            cond: 'AppStepReviewAndSubmitM2',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.ReviewAndSubmit,
            cond: 'AppStepReviewAndSubmitM3',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.ReviewAndSubmit,
            cond: 'AppStepAmendAndResubmit',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.ApprovalConfirmation,
            cond: 'ApprovalConfirmation',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.Declined,
            cond: 'Declined',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.PreApproval,
            cond: 'PreApproval',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
        ],
      },
      [LongFormStates.PersonalInformation]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.ContactInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.PersonalInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.PersonalInformation,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.PersonalInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
        },
      },
      [LongFormStates.ContactInformation]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.BillingAddress,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: [LongFormStates.PersonalInformation],
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.BillingAddress]: {
        // comes after basic info continue, but doesnt have an associated appStep
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.IncomeSource,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                order: (_context, event) => event.data.order,
                transitionedFrom: () => LongFormStates.BillingAddress,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.BillingAddress,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: [LongFormStates.ContactInformation],
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.BillingAddress,
              }),
            },
          ],
          [LongFormEvents.VIEW_CUSTOMER_ID_PROGRAM]: {
            target: LongFormStates.CustomerIdProgram,
          },
          [LongFormEvents.VIEW_TERMS_AND_CONDITIONS]: {
            target: LongFormStates.TermsAndConditions,
          },
          [LongFormEvents.VIEW_PREQUALIFICATION_NOTICE]: {
            target: LongFormStates.PrequalificationNotice,
          },
          [LongFormEvents.VIEW_AUTHORIZED_USER_TERMS]: {
            target: LongFormStates.AuthorizedUserTerms,
          },
        },
        meta: {
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.IncomeSource]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.BankingInformation, // is bav turned off for any long form stores?
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.IncomeSource,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.IncomeSource,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
        },
      },
      [LongFormStates.BankingInformation]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.DebitCardInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: LongFormStates.IncomeSource,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.DebitCardInformation]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.TransferToRapid,
              cond: (context, _event) =>
                (!context.highestSupportedApplicationMilestone || context.highestSupportedApplicationMilestone < 3) &&
                context.transitionedFrom !== LongFormStates.ReviewAndSubmit,
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: (context, _event) =>
                !!context.highestSupportedApplicationMilestone &&
                context.highestSupportedApplicationMilestone >= 3 &&
                context.transitionedFrom !== LongFormStates.ReviewAndSubmit,
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.DebitCardInformation,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: LongFormStates.BankingInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.DebitCardInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.DebitCardInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.DebitCardInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.ReviewAndSubmit]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.ContactInformation,
              cond: (_context, event) => {
                return event.data.target === LongFormStates.ContactInformation;
              },
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.PersonalInformation,
              cond: (_context, event) => {
                return event.data.target === LongFormStates.PersonalInformation;
              },
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.BillingAddress,
              cond: (_context, event) => event.data.target === LongFormStates.BillingAddress,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.IncomeSource,
              cond: (_context, event) => event.data.target === LongFormStates.IncomeSource,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.BankingInformation,
              cond: (_context, event) => event.data.target === LongFormStates.BankingInformation,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.DebitCardInformation,
              cond: (_context, event) => event.data.target === LongFormStates.DebitCardInformation,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.PreApproval,
              cond: (_context, event) => event.data.target === LongFormStates.PreApproval,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.InternalError,
              cond: (_context, event) => event.data.target === LongFormStates.InternalError,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },

            {
              target: LongFormStates.Declined,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
                hardDeclineError: (_context, event) => event.data.hardDeclineError,
              }),
            },
          ],
          [LongFormEvents.BACK]: {
            target: LongFormStates.DebitCardInformation,
            actions: assign({
              // TODO: [M3] Handle how far you can go 'back' in the flow from ReviewAndSubmit. This will create a loop between debit card and review pages
              transitionedFrom: () => LongFormStates.ReviewAndSubmit,
            }),
          },
        },
        // meta: {
        //   navigationSection: NavigationSections.REVIEW_AND_PAY,
        //   canGoBackViaHeader: true,
        // },
      },
      [LongFormStates.TransferToRapid]: {
        entry: 'transferToRapid',
      },

      // info pages
      [LongFormStates.CustomerIdProgram]: {
        on: {
          [LongFormEvents.NEXT]: { target: LongFormStates.BillingAddress },
        },
      },
      [LongFormStates.TermsAndConditions]: {
        on: {
          [LongFormEvents.NEXT]: {
            target: LongFormStates.BillingAddress,
          },
        },
      },
      [LongFormStates.PrequalificationNotice]: {
        on: {
          [LongFormEvents.NEXT]: {
            target: LongFormStates.BillingAddress,
          },
        },
      },
      [LongFormStates.AuthorizedUserTerms]: {
        on: {
          [LongFormEvents.BACK]: {
            target: LongFormStates.BillingAddress,
          },
        },
      },
      [LongFormStates.Declined]: {
        // TODO: [M3] What events can happen from the decline screen that need to be handled here?
      },
      [LongFormStates.PreApproval]: {
        on: {
          [LongFormEvents.APPROVED]: {
            target: LongFormStates.ApprovalConfirmation,
            actions: assign({
              transitionedFrom: () => LongFormStates.PreApproval,
            }),
          },
          [LongFormEvents.DECLINED_OR_ERROR]: {
            target: LongFormStates.Declined,
            actions: assign({
              transitionedFrom: () => LongFormStates.PreApproval,
            }),
          },
        },
        meta: {
          canGoBackViaHeader: false,
        },
      },
      [LongFormStates.ApprovalConfirmation]: {},
      [LongFormStates.InternalError]: {},
    },
    schema: {
      context: {} as LongFormMachineContext,
      events: {} as LongFormMachineEvents,
      guards: {} as LongFormMachineGuards,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      transferToRapid: () => {
        transferToRapid();
      },
      // strictly for debugging, do not remove and make sure you clean up references in MRs
      log: (context, event) => {
        console.log('Draft Application');
        console.log('context', context);
        console.log('event', event);
      },
    },
    guards: {
      NoApplication: (context) => {
        return !context.order?.application?.lease?.id;
      },
      NoAppStep: (context) => {
        return !context.order?.application?.lease?.appStep;
      },
      AppStepPersonalInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'personalInformation';
      },
      AppStepContactData: (context) => {
        return context.order?.application?.lease?.appStep === 'contactData';
      },
      AppStepIncomeSource: (context) => {
        return context.order?.application?.lease?.appStep === 'incomeSource';
      },
      AppStepBankingInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'bankingInformation';
      },
      AppStepDebitCardInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'debitCardInformation';
      },
      AppStepReviewAndSubmitM2: (context) => {
        return (
          (!context.highestSupportedApplicationMilestone || context.highestSupportedApplicationMilestone < 3) &&
          context.order?.application?.lease?.appStep === 'reviewAndSubmit' &&
          context.order?.application?.lease.status === 'draft'
        );
      },
      AppStepReviewAndSubmitM3: (context) => {
        return (
          !!context.highestSupportedApplicationMilestone &&
          context.highestSupportedApplicationMilestone >= 3 &&
          context.order?.application?.lease?.appStep === 'reviewAndSubmit' &&
          context.order?.application?.lease.status === 'draft'
        );
      },
      AppStepAmendAndResubmit: (context) => {
        return context.order?.application?.lease?.appStep === 'amendAndResubmit';
      },
      ApprovalConfirmation: (context) => {
        return context.skipToPage === 'ApprovalConfirmation';
      },
      Declined: (context) => {
        return context.skipToPage === 'Declined';
      },
      PreApproval: (context) => {
        return context.order?.application?.lease?.status === 'preApproved';
      },
      TransitionedFromReviewAndSubmit: (context) => {
        return context.transitionedFrom === LongFormStates.ReviewAndSubmit;
      },
      TransitionedFromAppSteps: (context) => {
        return context.transitionedFrom !== LongFormStates.ReviewAndSubmit;
      },
    },
  },
);
