import { gql } from '@apollo/client';
import { apolloQuery } from '../apollo';

export interface VirtualCards {
  getEmbedUrlById(orderId: string): Promise<object>;
}

const virtualCardService: VirtualCards = {
  async getEmbedUrlById(orderId: string) {
    return apolloQuery(EMBED_URL_QUERY, { orderId: orderId });
  },
};

export const EMBED_URL_QUERY = gql`
  query generateEmbeddedVirtualCardRequest($orderId: ID!) {
    generateEmbeddedVirtualCardRequest(orderId: $orderId) {
      embeddedVirtualCardRequest
      cardExpiration
      generateEmbeddedVirtualCardRequestErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on OrderNotFound {
          message
        }
        ... on AppNotFound {
          message
        }
        ... on VirtualCardNotFound {
          message
        }
      }
    }
  }
`;
export default virtualCardService;
