import jwt_decode from 'jwt-decode';
import { AppConfig } from '../../app.config';

const getCookie = (cookieName: string): string | undefined => {
  return document.cookie.split(`; `).find((cookie) => cookie.includes(cookieName));
};

export type transferToken = decodedToken & encodedToken;

interface decodedToken {
  token: string;
  dealerPublicId: string;
  targetUi: string;
  idvType: IDVType;
  customerId?: number;
  phoneNumber: string;
  applicationId?: number;
  exp: number;
}

enum IDVType {
  Full = 'Full', // Full" represents both OTP and last 4 + DOB check
  OTP = 'OTP',
}

interface encodedToken {
  token: string;
}

const tokenCookieName = 'unifiedSecurityToken';
export const getUnifiedToken = (): transferToken | null => {
  const matchedCookie = getCookie(tokenCookieName);
  if (matchedCookie) {
    const tokenCookie = matchedCookie.split('=') as [string, string];
    if (tokenCookie[0] === tokenCookieName) {
      try {
        let unifiedToken = jwt_decode(tokenCookie[1]) as transferToken;
        unifiedToken.token = tokenCookie[1];
        return unifiedToken;
      } catch (err) {
        // TOOD: remove this
        console.log('Could not verify token', err);
      }
    }
  }
  return null;
};

export enum Role {
  Customer = 'Customer',
  CustomerSupportRep = 'CSR',
  Admin = 'Admin',
  Sales = 'Sales',
  DealerSupportRep = 'DealerSupportRep',
  Company = 'Company',
  Group = 'Group',
  Store = 'Store',
  CSR = 'CSR',
  RapidSystem = 'RapidSystem',
  CSRManager = 'CSRManager',
  Trainer = 'Trainer',
  Farmer = 'Farmer',
  TrainingStore = 'TrainingStore',
}

const dealerPortalUrl = AppConfig.dealerPortalUrl.replace('https://', '');
const dealerCookieSuffix = '.AspNet.JwtCookies';
const roleKey = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export const getDealerCookie = (): Role | null => {
  const dealerCookie = getCookie(dealerPortalUrl + dealerCookieSuffix);
  if (dealerCookie) {
    try {
      const cookieValue = dealerCookie.split('=') as [string, string];
      const decodedToken = jwt_decode(cookieValue[1]) as any;

      if (decodedToken && decodedToken[roleKey]) {
        return decodedToken[roleKey];
      }
    } catch (err) {
      // TOOD: remove this
      console.log('Could not verify token', err);
    }
  }
  return null;
};
