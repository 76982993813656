import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { Popover, PopoverTrigger, PopoverContent, PopoverDescription } from '../popover/popover';

type LoanTermsTooltipProps = {
  styling?: any;
};

const DetailText = styled.div`
text-align: left;
font-size: 0.8rem;
max-width: 250px;
color: ${colors.almostBlack};
background-color: ${colors.white} !important;
font-family: ${fontFamilies.interLight};
padding: 0.8rem !important;
border: none;
box-shadow: 0px 2px 6px ${colors.darkGray} !important;
}
`;

const tooltipContent = (
  <DetailText>
    Koalafi loans are for qualifying customers with good to great credit. Koalafi loans are issued by The Bank of
    Missouri, serviced by Koalafi
  </DetailText>
);

const LoanTermsTooltip: React.FC<LoanTermsTooltipProps> = ({ styling = {} }) => {
  return (
    <Popover>
      <PopoverTrigger
        style={{
          display: 'inline-block',
          background: 'none',
          border: 'none',
          textDecoration: 'underline',
          color: colors.koalafiBlue,
          fontFamily: fontFamilies.interSemiBold,
          fontSize: '0.75rem',
          padding: '0',
          ...styling,
        }}
      >
        loan
      </PopoverTrigger>
      <PopoverContent className="Popover" style={{ zIndex: 2, display: 'inline-block' }}>
        <PopoverDescription>{tooltipContent}</PopoverDescription>
      </PopoverContent>
    </Popover>
  );
};

export default LoanTermsTooltip;
