import React from 'react';
import styled from '@emotion/styled';
import loader from '../../assets/loader.svg';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = ({ source }: { source: string }) => {
  return (
    <LoaderContainer data-testid={`${source}-loader`}>
      <img src={loader} alt="loader"></img>
    </LoaderContainer>
  );
};

export default Loader;
