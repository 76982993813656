import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import ApprovalAmount from '../approvalAmount/ApprovalAmount';
import Button from '../button/Button';
import Message from '../message/Message';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { modalResolver, ModalMessageFailure, ExitCodes, ModalMessage } from '../../helpers/modal/modal.helper';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { convertToCurrency } from '../../helpers/converters/converters';
import { ItemInput } from '../../gql/graphql';
import ListItems from '../listItems/ListItems';

type Props = {
  heading: any;
  body: string;
  cartTotal: number;
  approvalAmount: string;
  errorCode: AcceptedExitCodes;
  nonLeaseableItems?: ItemInput[];
  isOTB?: boolean;
};

// A union of ErrorCode values from the enum doesn't work.
// It's a TS "feature" that all number values are assignable to numeric enum types
// Hardcoding these numbers so compilation will break if codes passed are unexpected
// 4 === CartAmountNotMet
// 5 === NoCartItems
// 6 === CartAmountTooHigh
type AcceptedExitCodes = 4 | 5 | 6 | 8;

const ButtonWrap = styled.div`
  margin-top: 30px;

  & button {
    font-family: ${fontFamilies.gilroySemiBold};
    font-size: 1.3rem;
    font-weight: normal;
    margin-top: 0.5rem;
    height: 58px;
  }
`;

const CartTotal = styled.div`
  color: ${colors.red};
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px 0 20px 0;
`;

const Container = styled.div`
  div,
  h2 {
    text-align: left;
    font-family: ${fontFamilies.interLight} !important;
    font-size: 1.5rem !important;
    color: ${colors.darkBlue};
    margin-top: 2rem;
  }
`;

// const ShoppingImg = styled.img`
//   height: auto;
//   margin-top: -95px;
//   max-width: 100%;
//   position: relative;
//   z-index: 0;
// `;

const CartError = (props: Props) => {
  const orderId = useStoreState((state) => state.order.orderId!);
  const domains = useStoreState((state) => state.order.order?.dealer.domains!);

  useEffect(() => {
    const payloadDetails = {
      orderId,
      reasonCode: props.errorCode,
      reason: '',
    } as ModalMessageFailure;
    switch (props.errorCode) {
      case ExitCodes.cartAmtNotMet:
        payloadDetails.reason = 'CartAmountNotMet';
        break;
      case ExitCodes.noCartItems:
        payloadDetails.reason = 'NoCartItems';
        break;
      case ExitCodes.cartAmtTooHigh:
        payloadDetails.reason = 'CartAmountTooHigh';
        break;
      case ExitCodes.nonLeaseableTooHigh:
        payloadDetails.reason = 'NonLeaseableItemsTooHigh';
        break;
      default:
        payloadDetails.reason = 'Unknown Reason';
    }

    const payload: ModalMessage = {
      wcfSuccess: false,
      closeModal: false,
      payload: payloadDetails,
    };
    modalResolver(payload, domains);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    const payload: ModalMessage = {
      wcfSuccess: false,
      closeModal: true,
      payload: {
        reason: 'modal closed',
        reasonCode: ExitCodes.generalCloseModal,
        orderId: orderId,
      } as ModalMessageFailure,
    };
    modalResolver(payload, domains);
  };

  return (
    <Container data-testid="cart-error-message">
      {props.errorCode === 5 ? (
        <div>
          <Message heading={props.heading} body="" />
          <h2>{props.body}</h2>
          <ButtonWrap>
            <Button onClick={handleCloseModal}>Next: Continue shopping</Button>
          </ButtonWrap>
        </div>
      ) : (
        <div>
          <Message heading={props.heading} body={props.body} />
          {props.nonLeaseableItems ? <ListItems items={props.nonLeaseableItems} /> : <p></p>}
          <CartTotal>Cart total: {convertToCurrency(props.cartTotal)}</CartTotal>
          <ApprovalAmount amount={props.approvalAmount} isOTB={props.isOTB} />
          <ButtonWrap>
            <Button onClick={handleCloseModal}>Back to cart</Button>
          </ButtonWrap>
        </div>
      )}
    </Container>
  );
};

export default CartError;
