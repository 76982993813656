const ORG_ID = process.env['REACT_APP_THREAT_METRIX_ORG_ID'];

const setupSession = (checkoutId: string) => {
  const script = document.createElement('script');

  script.src = `https://content.koalafi.com/fp/tags.js?org_id=${ORG_ID}&session_id=${checkoutId}`;
  script.defer = true;

  document.body.appendChild(script);
};

export { setupSession };
