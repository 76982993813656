import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { modalResolver, ModalMessage, ExitCodes, ModalMessageFailure } from '../../helpers/modal/modal.helper';
import { AppMode } from '../../stores/order.store';
import { useTrack } from '../../hooks/segment';
import { ReactComponent as WallyWithConfetti } from '../../assets/Wally_With_Confetti.svg';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';
import { FormNavButtonWrapper } from '../../components/formElements/FormElements';
import Button from '../../components/button/Button';
import { inIframe } from '../../helpers';

type Props = {
  onNext?: any;
};

const HiveFiveStyle = styled.div`
  position: absolute;
  width: 7rem;
  left: 70%;
  bottom: -20%;
  z-index: 1;
`;
const SubTextStyle = styled.div`
  font-family: ${fontFamilies.interLight};
  font-size: 1.1rem;
  color: ${colors.darkBlue};
  width: 70%;
  line-height: 125%;
  margin: 1rem 0;
`;
const PageWrapper = styled.div`
  text-align: left;
  margin: 1rem 0;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  color: ${colors.darkBlue};
`;

const PaymentTermsSubtext = styled.div`
  color: ${colors.darkBlue};
  font-family: ${fontFamilies.interLight};
  font-size: 1rem;
  margin: 1rem 0;
  line-height: 125%;
  text-align: left;
`;

const Gradient = styled.div`
  width: 100%;
  max-width: 465px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
  margin-bottom: 2rem;
  height: 50px;
`;

const LeaseSigned: React.FC<Props | undefined> = () => {
  const { orderId, appMode } = useStoreState((state) => state.order);
  const domains = useStoreState((state) => state.order.order?.dealer.domains!);
  const application = useStoreState((state) => state.order?.order?.application);
  const loanSigned = useStoreState((state) => state.loan.loanAgreementSigned);
  const leaseSigned = useStoreState((state) => state.applicationForm.leaseAgreementSigned);
  const track = useTrack();

  const handleCloseModal = () => {
    const payload: ModalMessage = {
      wcfSuccess: true,
      closeModal: true,
      payload: {
        reason: 'modal closed',
        reasonCode: ExitCodes.generalCloseModal,
        orderId: orderId,
      } as ModalMessageFailure,
    };
    track(appMode === AppMode.lease ? 'Lease Complete' : 'Loan Complete');
    modalResolver(payload, domains);
  };

  useEffect(() => {
    const sendSuccessEvent = () => {
      const payload: ModalMessage = {
        wcfSuccess: true,
        closeModal: false,
        payload: { orderId: orderId },
      };

      modalResolver(payload, domains);
    };

    // despite its name, this page is used for leases and loans
    if (application?.lease) {
      track('Lease Agreement Signed');
    } else {
      track('Loan Agreement Signed');
    }

    //if app status is not in docssigned
    if (loanSigned || leaseSigned) {
      sendSuccessEvent();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageWrapper>
      <h1 style={{ fontSize: '3.5rem' }}>
        You're
        <br />
        all done
      </h1>
      <ImageWrapper>
        <SubTextStyle>Thanks for checking out with Koalafi.</SubTextStyle>
        <HiveFiveStyle>
          <WallyWithConfetti title="lease signed" />
        </HiveFiveStyle>
      </ImageWrapper>
      <Gradient />
      <PaymentTermsSubtext>
        We'll email you your payment terms and {appMode === AppMode.lease ? 'lease' : 'loan'} agreement shortly.
        {inIframe() && 'If you are not automatically redirected, click the button below to continue.'}
      </PaymentTermsSubtext>
      {inIframe() && (
        <FormNavButtonWrapper>
          <Button onClick={handleCloseModal}>Continue</Button>
        </FormNavButtonWrapper>
      )}
    </PageWrapper>
  );
};

export default LeaseSigned;
