/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from '@emotion/styled';

interface ContainerProps {
  children: React.ReactNode;
}

const ContainerCentering = styled.div`
  padding: 0;
  width: 100%;
  position: relative;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 465px;
  margin: 0 auto;
  min-height: 100vh;
`;

// const Content = styled.div`
//   width: 100%;
//   align-items: center;
//   display: flex;
//   flex: 1 1 100%;
//   flex-direction: column;
//   justify-content: space-between;
// `;

const FullScreen: React.FC<ContainerProps> = ({ children }) => {
  return (
    <ContainerCentering>
      <Container>{children}</Container>
    </ContainerCentering>
  );
};

export default FullScreen;
