interface ValidateDateInput {
  year: string;
  month: string;
  day: string;
  errorMsg?: string;
  maxYearsAgo?: number;
  minYearsAgo?: number;
  inFuture?: boolean;
  inPast?: boolean;
  preventPastDates?: boolean;
}

interface DateObject {
  year: string;
  month: string;
  day: string;
}

const validateDate = ({
  year,
  month,
  day,
  errorMsg,
  maxYearsAgo,
  minYearsAgo,
  inFuture,
  inPast,
  preventPastDates,
}: ValidateDateInput): string => {
  const now = new Date();
  const dateProvided = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  if (!(dateProvided instanceof Date && !isNaN(dateProvided as any))) {
    const reason = 'not an instace of a date';
    console.log('invalid reason:', reason);
    return errorMsg || reason;
  }

  if (maxYearsAgo) {
    const maxYearsAgoDate = new Date();
    maxYearsAgoDate.setFullYear(now.getFullYear() - maxYearsAgo);
    if (dateProvided < maxYearsAgoDate) return errorMsg || `Year provided is before ${maxYearsAgo} years ago`;
  }

  if (minYearsAgo) {
    const minYearsAgoDate = new Date();
    minYearsAgoDate.setFullYear(now.getFullYear() - minYearsAgo);
    if (dateProvided > minYearsAgoDate) return errorMsg || `Year provided is after ${minYearsAgo} years ago`;
  }

  if (inPast) {
    if (dateProvided > now) return errorMsg || `Date provided is in the future`;
  }

  if (inFuture) {
    if (dateProvided < now) return errorMsg || `Date provided is in the past`;
  }

  if (preventPastDates) {
    if (now > dateProvided) return errorMsg || `Date provided is in the past`;
  }

  return '';
};

const formatToYYYYMMDD = ({ day, month, year }: DateObject): string => {
  let dateValue = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  return `${dateValue.getFullYear()}-${('0' + (dateValue.getMonth() + 1)).slice(-2)}-${(
    '0' + dateValue.getDate()
  ).slice(-2)}`;
};

/**
 * Converts a date to M/D/YYYY for display (or M/YYYY if no day is provided)
 * @param date Takes a DateObject or date string in YYYYMMDDFormat
 */
const formatToMDYYYY = (date: DateObject | string): string => {
  let dateObject: DateObject;
  if (typeof date === 'string') {
    dateObject = parseDateToSeparateFields(date);
  } else {
    dateObject = date;
  }

  if (dateObject.day) {
    return `${parseInt(dateObject.month)}/${parseInt(dateObject.day)}/${parseInt(dateObject.year)}`;
  }
  return `${parseInt(dateObject.month)}/${parseInt(dateObject.year)}`;
};

const parseDateToSeparateFields = (dateInYYYYMMDDFormat: string | undefined): DateObject => {
  if (!dateInYYYYMMDDFormat) {
    return {
      year: '',
      month: '',
      day: '',
    };
  }
  const splitDate = dateInYYYYMMDDFormat.split('-');
  return {
    year: splitDate[0],
    month: splitDate[1],
    day: splitDate[2],
  };
};

const parseTwoDigitYearDate = ({ day, month, year }: DateObject, todaysDate: Date): Date => {
  const twoDigitYearMaxOffset = todaysDate.getFullYear() + 50;
  const parsedDecadeAndYear = parseInt(year);

  return new Date(
    `${month}/${day}/${
      (Math.floor(twoDigitYearMaxOffset / 100) - (parsedDecadeAndYear > twoDigitYearMaxOffset % 100 ? 1 : 0)) * 100 +
      parsedDecadeAndYear
    }`,
  );
};

export { validateDate, formatToYYYYMMDD, formatToMDYYYY, parseDateToSeparateFields, parseTwoDigitYearDate };
