import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';
import DotLoader from '../dotLoader/DotLoader';

const SharedButton = styled('button')<IProps>`
  font-family: ${fontFamilies.interSemiBold};
  font-size: 1rem;
  line-height: 125%;
  background: ${colors.koalafiBlue};
  border: none;
  border-radius: 0.625rem;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  margin: 1rem 0 0.75rem;
  transition: all 0.4s;
  text-decoration: none;
  width: 100%;

  &:disabled {
    color: ${colors.darkGray};
    background: ${colors.lightGrey};
    cursor: default;
  }
`;

export interface IProps {
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  dataLoading?: boolean;
  onClick?: (e: any) => any;
  active?: boolean;
}

const Button: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { disabled, dataLoading, children } = props;

  return (
    <SharedButton {...props} disabled={dataLoading || disabled}>
      {dataLoading ? <DotLoader color={colors.white} /> : children}
    </SharedButton>
  );
};

export default Button;
