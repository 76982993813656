import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';

interface ProgressSectionProps {
  progressText: string;
  isHighlighted: { eligibility: boolean; details: boolean; review: boolean };
}

type StylingProps = {
  isSelected?: boolean;
  color?: string;
};

const SectionContainerStyle = styled.div`
  width: calc(100% / 3);
`;

const ProgressSectionStyle = styled.div<StylingProps>`
  color: ${colors.gray};
  font-family: ${(props: any) => (props.isSelected ? fontFamilies.interRegular : fontFamilies.interLight)};
  color: ${(props: any) => (props.isSelected ? colors.koalafiBlue : colors.gray)};
  font-size: 0.8rem;
  text-align: center;
  @media (max-width: 370px) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  height: 10px;
  width: 10px;
  border: 1px ${colors.koalafiBlue} solid;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
`;
const FullBar = styled.div`
  background: ${colors.koalafiTurquoise};
  height: 1px;
  width: 100%;
`;

const HalfBar = styled.div<StylingProps>`
  background: ${(props: StylingProps) => props.color};
  height: 1px;
  width: calc((100% - 10px) / 2);
`;

const ColorStyle = styled.div`
  display: flex;
  align-items: center;
  height: 12px;
`;

const ThirdProgress: React.FC<ProgressSectionProps> = ({ isHighlighted, progressText }) => {
  const gradientColor = `linear-gradient(90deg, ${colors.koalafiBlue}  35%, ${colors.koalafiTurquoise}  100%)`;
  const blueColor = colors.koalafiBlue;
  return (
    <SectionContainerStyle>
      <ColorStyle>
        {(!!isHighlighted.eligibility || !!isHighlighted.details) && <FullBar />}
        {!!isHighlighted.review && (
          <>
            <HalfBar color={blueColor} />
            <Circle />
            <HalfBar color={gradientColor} />
          </>
        )}
      </ColorStyle>
      <ProgressSectionStyle isSelected={isHighlighted.review}>{progressText}</ProgressSectionStyle>
    </SectionContainerStyle>
  );
};

export default ThirdProgress;
