/* istanbul ignore file */
import { acceptedDomain, currentDomain, inIframe } from '../window/window.helpers';

// Modal to send postMessages to SDK to handle modal closes + application status
export const modalResolver = (payload: ModalMessage | ModalMessageNoData, domains: string[], appId?: number) => {
  if (inIframe()) {
    const domain = acceptedDomain(domains);
    if (domain) {
      if (appId === 999999999999999) {
        sessionStorage.removeItem('publicDealerId');
        const { closeModal } = payload;
        window.parent.postMessage(JSON.stringify({ closeModal: closeModal }), domain);
      } else {
        window.parent.postMessage(JSON.stringify(payload), domain);
        sessionStorage.removeItem('publicDealerId');
      }
    } else {
      const { closeModal } = payload;

      console.error('Issue with postmessage. Origin: ', currentDomain());
      window.parent.postMessage(JSON.stringify({ closeModal: closeModal }), document.referrer);
    }
  }
};

// Helper to remove loading spinner from SDK once UI has loaded successfully.
export const modalLoaderHelper = (payload: ModalLoadingMessage, domains: string[]) => {
  if (inIframe()) {
    const domain = acceptedDomain(domains);
    if (domain) {
      window.parent.postMessage(JSON.stringify(payload), domain);
    } else {
      console.error('Issue with postmessage. Origin: ', currentDomain());
      window.parent.postMessage(JSON.stringify(payload), document.referrer);
    }
  }
};

export enum ExitCodes {
  genericError = 1,
  hardDecline = 2,
  ineligible = 3,
  cartAmtNotMet = 4,
  noCartItems = 5,
  cartAmtTooHigh = 6,
  generalCloseModal = 7,
  nonLeaseableTooHigh = 8,
  genericCartValidationErr = 9,
}

interface ModalMessageEvent {
  wcfSuccess: boolean;
  payload: object;
  closeModal: boolean;
  stopSpinner?: boolean;
}
interface PostMsgFailure {
  reason: string;
  reasonCode: ExitCodes;
}

interface ModalMessageEventNoData {
  closeModal: true;
}

export interface ModalLoadingMessage {
  stopSpinner?: boolean;
  startSpinner?: boolean;
}

export type ModalMessage = ModalMessageEvent;
export type ModalMessageFailure = PostMsgFailure;
export type ModalMessageNoData = ModalMessageEventNoData;
