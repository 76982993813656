import { ChannelTypes, Dealer, LeaseOptions } from '../../gql/graphql';
import { inIframe } from '../window/window.helpers';

/**
 * greatestApprovalAmountHelper gets the greatest approval amount from all lease options
 */
const greatestApprovalAmountHelper = (leaseOptions: LeaseOptions[] | null | undefined, cartTotal: number) => {
  if (!leaseOptions || leaseOptions.length === 0) {
    return null;
  }

  if (leaseOptions.length === 1) {
    return leaseOptions[0].approvedAmount;
  }

  var leaseOptionsWithinMinimumSpend = leaseOptions.filter(
    (leaseOption) => cartTotal === 0 || Number(leaseOption.minimumSpend) <= cartTotal,
  );

  if (leaseOptionsWithinMinimumSpend.length === 0) {
    return null;
  }

  return Math.max(
    ...leaseOptionsWithinMinimumSpend.map((leaseOption) => Number(leaseOption.approvedAmount)),
  ).toString();
};

/**
 * selectLeaseHelper gets the lease with the greatest approval amount
 */
const selectLeaseHelper = (leaseOptions: LeaseOptions[] | null | undefined) => {
  if (!leaseOptions || leaseOptions.length === 0) return null;

  return leaseOptions.reduce((prevVal, curVal) =>
    Number(curVal?.approvedAmount) > Number(prevVal?.approvedAmount) ? curVal : prevVal,
  );
};

/**
 * selectLeaseHelper gets the lease with the lowest minimum spend
 */
const getLowestMinimumSpend = (leaseOptions: LeaseOptions[] | null | undefined) => {
  if (!leaseOptions || leaseOptions.length === 0) return null;

  return leaseOptions.length === 1
    ? Number(leaseOptions[0].minimumSpend)
    : Math.min(...leaseOptions.map((leaseOption) => Number(leaseOption.minimumSpend)));
};

const getEcommApprovalOnly = (dealer?: Dealer) => {
  // Currently only allowing to continue through approval if in full screen
  // Not brick and mortar AND not virtual card
  let isEcomm;
  let isVC;
  if (!!dealer) {
    isEcomm = dealer?.channelType !== ChannelTypes.BrickAndMortar;
    isVC = dealer?.isVirtualCard;
  }
  const isModal = inIframe();
  return !isModal && !!isEcomm && !isVC;
};

export { greatestApprovalAmountHelper, selectLeaseHelper, getLowestMinimumSpend, getEcommApprovalOnly };
