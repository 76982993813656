import React from 'react';
import Message from '../../components/message/Message';
import { usePage, useTrack } from '../../hooks/segment';

type DebitCardErrorProps = {
  onNext: any;
};

const DebitCardError: React.FC<DebitCardErrorProps> = ({ onNext }) => {
  const track = useTrack();
  const page = usePage();

  page('Debit Card Error');
  track('Continuing to debit card screen');

  const handleButtonClick = () => {
    onNext();
  };

  return (
    <Message
      heading={"There's a problem with your debit card information."}
      body={'Debit card information is incorrect, please update to continue application.'}
      buttonText={'Continue'}
      buttonClick={handleButtonClick}
    />
  );
};

export default DebitCardError;
