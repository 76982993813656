const getCardProvider = (firstTwoDigits: String) => {
  switch (firstTwoDigits) {
    case '34':
    case '37':
      return 'American Express';
    case '40':
    case '41':
    case '42':
    case '43':
    case '44':
    case '45':
    case '46':
    case '47':
    case '48':
    case '49':
      return 'Visa';
    case '51':
    case '52':
    case '53':
    case '54':
    case '55':
      return 'Mastercard';
    case '60':
    case '64':
    case '65':
      return 'Discover';
    default:
      return '';
  }
};

export { getCardProvider };
