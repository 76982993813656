import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as BackButtonImage } from '../../assets/back_arrow.svg';

const ModalCloseButton = styled.div`
  display: block;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  appearance: none;
  background: none;
  border: 0 none;
  margin-left: 1rem;
  width: 16px;
  height: 16px;
`;
type Props = { handleClick: () => void };

const BackButton: React.FC<Props> = ({ handleClick }) => {
  return (
    <ModalCloseButton onClick={handleClick} tabIndex={0} aria-label="Go back" role="button">
      <BackButtonImage />
    </ModalCloseButton>
  );
};

export default BackButton;
