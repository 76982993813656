import React from 'react';
import styled from '@emotion/styled';
import Tooltip from '../tooltipV2/Tooltip';
import { colors, fontFamilies } from '../baseStyle/Variables';

type MonthlyPaymentContentProps = {
  monthlyPayment: string | null;
  payFrequency: string;
};

const paymentFrequencyLabels: { [index: string]: string } = {
  weekly: 'weekly',
  biweekly: 'every two weeks',
  monthly: 'monthly',
  semimonthly: 'twice per month',
};

const subheaderTooltip = (
  <div>
    Your Koalafi lease has an initial term of less than 3 months and the term renews with each scheduled payment. You
    can contact Koalafi at 844.937.8275 anytime and voluntarily return or surrender the leased property in good repair,
    ordinary wear and tear expected, following the directions. Please see your agreement for details. Koalafi does not
    charge voluntary return fees if you follow our return directions. I authorize Koalafi to debit payments and fees
    from the provided bank account. I also acknowledge payment rights and obligations will be described in the Payment
    Method section in their lease agreement.
  </div>
);

const MonthlyPaymentStyle = styled.div`
  color: ${colors.koalafiBlue};
  display: inline-block;
  div {
    font-family: ${fontFamilies.gilroyBold};
    font-size: 2.5rem;
    display: inline-block;
    margin-right: 0.3rem;
  }
  font-size: 0.9rem;
  font-family: ${fontFamilies.interLight};
`;

const MonthlyPaymentSubheader = styled.div`
  color: ${colors.darkBlue};
  font-size: 0.9rem !important;
  margin-top: 0.5rem;
  line-height: 125%;
  margin-bottom: 0.5rem;
  width: 95%;
`;
const MonthlyPaymentContent: React.FC<MonthlyPaymentContentProps> = ({ monthlyPayment, payFrequency }) => {
  return (
    <div>
      {!!monthlyPayment && (
        <MonthlyPaymentStyle>
          <div>{monthlyPayment}</div>
          {paymentFrequencyLabels[payFrequency]}
        </MonthlyPaymentStyle>
      )}
      <MonthlyPaymentSubheader>
        Starting after your items ship, you'll make regular rental payments{' '}
        <strong>{paymentFrequencyLabels[payFrequency]}</strong> over the life of your lease or you can purchase early
        and save.
        <Tooltip id="subheader-tooltip" tooltipContent={subheaderTooltip} inline={true} size="12px" />
      </MonthlyPaymentSubheader>
    </div>
  );
};

export default MonthlyPaymentContent;
