import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as CloseButtonImage } from '../../assets/x_icon.svg';
import { ExitCodes, ModalMessage, ModalMessageFailure, modalResolver } from '../../helpers/modal/modal.helper';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { useTrack } from '../../hooks/segment';

// TODO: this is a very hacky TEMPORARY fix, we should properly support embedded iframe flow ASAP
// Context: this is being done because TireAgent is doing some hacky logic to be able to remove the modal
// aspect of UI thru the SDK and keep just the iframe portion. Because the UI is embedded, we do not want to
// have our own "X" close button as this can cause issues if clicked
const noCloseButtonDealers = [
  'f6801105-7ded-4fa8-a70a-85fcdb70d7d5', // TireAgent Sandbox
  '3adcdba5-0b07-457b-ab4c-9cd97e0cb724', // TireAgent Prod
];

const ModalCloseButton = styled.div`
  display: block;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  appearance: none;
  background: none;
  border: 0 none;
  margin-left: auto;
  margin-right: 1rem;
  width: 16px;
  height: 16px;
`;
type Props = {};

const CloseButton: React.FC<Props> = () => {
  const track = useTrack();

  const { orderId } = useStoreState((state) => state.order);
  const domains = useStoreState((state) => state.order.order?.dealer.domains!);
  const publicDealerId = useStoreState((state) => state.order.order?.dealer.publicDealerId);

  const handleCloseModal = () => {
    const payload: ModalMessage = {
      wcfSuccess: false,
      closeModal: true,
      payload: {
        reason: 'closed modal with X button',
        reasonCode: ExitCodes.generalCloseModal,
        orderId: orderId,
      } as ModalMessageFailure,
    };
    track('closed modal with X button', { orderId });
    modalResolver(payload, domains);
  };

  return !(publicDealerId && noCloseButtonDealers.includes(publicDealerId)) ? (
    <ModalCloseButton onClick={handleCloseModal} tabIndex={0} aria-label="Close Koalafi" role="button">
      <CloseButtonImage />
    </ModalCloseButton>
  ) : (
    <div />
  );
};

export default CloseButton;
