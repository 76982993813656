import { Computed, computed } from 'easy-peasy';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { AppModel } from './storeModel';
import {
  Application,
  CardPayment,
  ContractDetails,
  FormTypes,
  Item,
  ItemInput,
  NewOrder,
  Option,
  Order,
  UpdateOrderItemsInput,
} from '../gql/graphql';
import { selectLeaseHelper } from '../helpers/leases/leases.helpers';
import { selectLoanHelper, selectLoanHelperOTB } from '../helpers/loans/loans.helpers';
import { createForbiddenErrorMessage, createUnauthorizedErrorMessage } from '../helpers/security/security';

export interface OrderModel {
  // General order error
  error?: ErrorMessage | null;
  setErrorGeneral: Action<OrderModel, ErrorMessage | null>;

  // orderId and setting
  orderId?: string | null;
  setOrderId: Action<OrderModel, string>;

  // order query
  order?: Order;
  application: Application | null;
  setApplication: Action<OrderModel, Application | null | undefined>;
  setDebitInfo: Action<OrderModel, CardPayment | null | undefined>;
  setOrder: Action<OrderModel, Order>;
  setOrderItems: Action<OrderModel, Item[]>;
  setErrorOrder?: Action<OrderModel, ErrorMessage>;

  createOrder: Thunk<OrderModel, NewOrder, any, AppModel>;
  queryOrder: Thunk<OrderModel, string, any, AppModel>;
  queryOrderPreAuth: Thunk<OrderModel, string, any, AppModel>;
  updateOrderItems: Thunk<OrderModel, UpdateOrderItemsInput, any, AppModel>;

  // related to items in the cart math
  totalCartAmount: Computed<OrderModel, number>;

  nonLeasableItems: Computed<OrderModel, ItemInput[]>;

  // used to determine if order has items in it.  Made this a convenience method for determining shop first flow. So use this if you want
  //to determine at a specific time for shop first flow, Basically before the addItems api call to validate shop first flow
  hasItems: Computed<OrderModel, boolean>;

  friendlyName: Computed<OrderModel, string | null | undefined>;

  // used to determine what type of order we are using
  appMode: Computed<OrderModel, AppMode | null>;

  isLongForm: Computed<OrderModel, boolean>;

  //Set Loan Summary
  setSummary: Action<OrderModel, ContractDetails>;
}

export enum AppMode {
  lease = 'lease',
  loan = 'loan',
}
export interface ErrorMessage {
  developerText?: string;
  displayText?: string;
  stackTrace?: any;
}

const order: OrderModel = {
  /**
   * GENERAL ORDER ERRORS: GET/SET
   * App errors, general non-query-specific related errors
   */
  // error for order
  error: null,
  // set order error
  setErrorGeneral: action((state, payload?) => {
    state.error = payload;
  }),

  /**
   * ORDER ID AND QUERY
   * getting/setting orderId, getting order query
   */
  // orderId
  orderId: null,
  // setOrderId
  setOrderId: action((state, payload?) => {
    state.orderId = payload;
  }),

  application: null,
  setApplication: action((state, payload: Application) => {
    state.order!.application = payload;
  }),
  setDebitInfo: action((state, payload: CardPayment) => {
    state.order!.application!.lease!.card = payload;
  }),
  // set order query response
  setOrder: action((state, payload?) => {
    state.order = payload;
  }),
  setOrderItems: action((state, payload?) => {
    state.order!.details.items = payload ?? [];
  }),
  setSummary: action((state, payload: ContractDetails) => {
    state.order!.application!.loan!.summary = payload;
  }),
  // query for order by UUID
  queryOrder: thunk(async (actions, orderId, { injections, getStoreState, getStoreActions }) => {
    const { orderService } = injections;
    const vcState = getStoreState().virtualCard;
    const vcActions = getStoreActions().virtualCard;
    const authActions = getStoreActions().auth;

    try {
      const result = await orderService.getOrderById(orderId);
      const orderData = result.data.order;

      if (orderData?.application?.lease?.id === 0 || orderData?.application?.loan?.id === 0) {
        const unauthErrMsg = createUnauthorizedErrorMessage('customer not authorized to retrieve application');
        authActions.setUnauthorizedError(unauthErrMsg);
        return unauthErrMsg;
      }

      actions.setOrder(orderData);

      // set VC approval amount if app exists and not already set
      if (!vcState.approvalAmount && orderData.application) {
        if (orderData.application?.lease) {
          /**
           * NOTE: In MVP, the highest approval option is automatically selected
           * In post-MVP, there will be an additional screen to select lease term
           * TODO: Remove logic to automatically select lease term in post-MVP
           */
          // get leaseOption with highest approval amount
          const leaseTerm = selectLeaseHelper(orderData.application?.lease?.options);
          if (leaseTerm) {
            vcActions.setApprovalAmount(parseFloat(leaseTerm.approvedAmount));
          }
        } else if (orderData.application?.loan) {
          let selectedLoanProduct: Option;
          if (orderData.application?.loan?.isOtb) {
            selectedLoanProduct = selectLoanHelperOTB(orderData.application?.loan?.options);
          } else {
            selectedLoanProduct = selectLoanHelper(orderData.application?.loan?.options);
          }
          vcActions.setApprovalAmount(parseFloat(selectedLoanProduct?.lineAmount.toString()));
        }
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          return forbiddenErrMsg;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          return unauthErrMsg;
        default:
          const errorMessage: ErrorMessage = {
            stackTrace: err,
            displayText: 'Unable to fetch order',
          };
          actions.setErrorGeneral(errorMessage);
          return errorMessage;
      }
    }
    return null;
  }),

  queryOrderPreAuth: thunk(async (actions, orderId, { injections, getStoreState, getStoreActions }) => {
    const { orderService } = injections;
    const appState = getStoreState().applicationForm;
    const appActions = getStoreActions().applicationForm;
    const vcState = getStoreState().virtualCard;
    const vcActions = getStoreActions().virtualCard;
    const authActions = getStoreActions().auth;

    try {
      const result = await orderService.getOrderById(orderId);
      const orderData = result.data.order;

      actions.setOrder(orderData);

      // on app load, fill application form state from order response if possible,
      // only doing so if it will not overwrite existing data.
      if (Object.values(appState.basicInfo).every((val) => !val)) {
        appActions.setBasicInfo({
          firstName: orderData?.application?.customer?.firstName ?? '',
          lastName: orderData?.application?.customer?.lastName ?? '',
          emailAddress: orderData?.application?.customer?.emailAddress ?? '',
        });
      }

      if (Object.values(appState.basicInfoContinued).every((val) => !val)) {
        appActions.setBasicInfoContinued({
          birthDate: orderData?.application?.customer?.birthDate ?? '',
          taxpayerIdentificationNumber: '', // raw SSN would never be returned by order query
          tokenizedTaxId: orderData?.application?.customer?.tokenizedTaxId ?? '',
          monthlyIncome: orderData?.application?.lease?.employment?.monthlyIncome ?? '',
        });
      }

      if (!appState.cellPhone) {
        appActions.setCellPhone(orderData?.application?.customer?.cellPhone ?? '');
      }

      if (Object.values(appState.billingAddress).every((val) => !val)) {
        appActions.setBillingAddress({
          line1: orderData?.application?.customer?.billingAddress?.line1 ?? '',
          line2: orderData?.application?.customer?.billingAddress?.line2 ?? '',
          city: orderData?.application?.customer?.billingAddress?.city ?? '',
          state: orderData?.application?.customer?.billingAddress?.state ?? '',
          zip: orderData?.application?.customer?.billingAddress?.zip ?? '',
        });
      }

      if (Object.values(appState.payFrequency).every((val) => !val)) {
        appActions.setPayFrequency({
          paymentFrequency: orderData?.application?.lease?.employment?.payPeriod ?? '',
          lastPayDate: orderData?.application?.lease?.employment?.lastPayDate ?? '',
          nextPayDate: orderData?.application?.lease?.employment?.nextPayDate ?? '',
          isPaidViaDirectDeposit: orderData?.application?.lease?.employment?.isPaidViaDirectDeposit ?? false,
        });
      }

      // set VC approval amount if app exists and not already set
      if (!vcState.approvalAmount && orderData.application) {
        if (orderData.application?.lease && orderData.application.lease.id !== 0) {
          /**
           * NOTE: In MVP, the highest approval option is automatically selected
           * In post-MVP, there will be an additional screen to select lease term
           * TODO: Remove logic to automatically select lease term in post-MVP
           */
          // get leaseOption with highest approval amount
          const leaseTerm = selectLeaseHelper(orderData.application?.lease?.options);
          if (leaseTerm) {
            vcActions.setApprovalAmount(parseFloat(leaseTerm.approvedAmount));
          }
        } else if (orderData.application?.loan && orderData.application.loan.id !== 0) {
          let selectedLoanProduct: Option;
          if (orderData.application?.loan?.isOtb) {
            selectedLoanProduct = selectLoanHelperOTB(orderData.application?.loan?.options);
          } else {
            selectedLoanProduct = selectLoanHelper(orderData.application?.loan?.options);
          }
          vcActions.setApprovalAmount(parseFloat(selectedLoanProduct?.lineAmount.toString()));
        }
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          return forbiddenErrMsg;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          return unauthErrMsg;
        default:
          const errorMessage: ErrorMessage = {
            stackTrace: err,
            displayText: 'Unable to fetch order',
          };
          actions.setErrorGeneral(errorMessage);
          return errorMessage;
      }
    }
    return null;
  }),
  updateOrderItems: thunk(async (actions, input, helpers) => {
    const { orderService } = helpers.injections;
    const authActions = helpers.getStoreActions().auth;

    try {
      const result = await orderService.updateOrderItems(input);
      actions.setOrderItems(result.data.updateOrderItems.order.details.items);
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          return forbiddenErrMsg;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          return unauthErrMsg;
        default:
          const errorMessage: ErrorMessage = {
            stackTrace: err,
            displayText: 'Unable to update order items',
          };
          actions.setErrorGeneral(errorMessage);
          return errorMessage;
      }
    }

    return null;
  }),

  totalCartAmount: computed((state) => {
    let totalCartAmount = 0;

    try {
      state.order?.details?.items?.forEach((item) => {
        totalCartAmount += item.quantity * parseFloat(item.price);
      });

      if (state.order?.details?.shippingAmnt) {
        totalCartAmount += parseFloat(state.order?.details?.shippingAmnt);
      }
      if (state.appMode === AppMode.loan && state.order?.details?.taxAmnt) {
        totalCartAmount += parseFloat(state.order?.details?.taxAmnt);
      }
    } catch (err) {
      console.error('could not compute items total amount: ', err);
    }
    return totalCartAmount;
  }),

  nonLeasableItems: computed((state) => {
    let nonLeasableItems: ItemInput[] = [];
    state.order?.details?.items?.forEach((item) => {
      if (!item.isLeaseable) {
        nonLeasableItems.push(item);
      }
    });
    return nonLeasableItems;
  }),

  hasItems: computed((state) => {
    if (state.order?.details?.items && state.order?.details?.items.length > 0) {
      return true;
    } else {
      return false;
    }
  }),

  friendlyName: computed((state) => {
    return state.order?.dealer?.friendlyName || state.order?.dealer?.storeName;
  }),

  appMode: computed(
    [(state) => state, (state, storeState: any) => storeState.applicationForm],
    (state, applicationForm) => {
      // We check is lease exists first. Reasoning: in a full spectrum scenario,
      // the flow goes loan apply -> lease apply (if loan declined). If there is a lease
      // on the application, this takes precedence, as it's either gone through loan decisioning
      // or it's lto_only.

      if (state.order?.application?.lease) {
        return AppMode.lease;
      } else if (state.order?.application?.loan) {
        return AppMode.loan;
      }

      if (applicationForm?.application?.lease) {
        return AppMode.lease;
      } else if (applicationForm?.application?.loan) {
        return AppMode.loan;
      }

      if (state.order?.dealer.financingType === 'full_spectrum' || state.order?.dealer.financingType === 'loan_only') {
        // Don't want to return null for app mode ever, will always be loan or lease.
        // If neither is on the application, mode will be determined by financing_type
        return AppMode.loan;
      } else {
        return AppMode.lease;
      }
    },
  ),

  isLongForm: computed((state) => {
    return state.order?.dealer?.formType === FormTypes.Long || state.order?.dealer?.formType === FormTypes.LongV2;
  }),

  createOrder: thunk(async (actions, newOrder: NewOrder, helpers) => {
    const { orderService } = helpers.injections;

    try {
      const result = await orderService.createOrder(newOrder);
      actions.setOrderId(result.data.createOrder.order.id);
    } catch (err) {
      const errorMessage: ErrorMessage = {
        stackTrace: err,
        displayText: 'Unable to fetch order',
      };
      actions.setErrorGeneral(errorMessage);
    }
  }),
};

export default order;
