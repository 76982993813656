import styled from '@emotion/styled';
import { fontFamilies } from '../baseStyle/Variables';

const FlowContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${fontFamilies.gilroySemiBold};
  height: 100%;
  flex: 1;
`;

export default FlowContainerWrapper;
