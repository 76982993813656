import styled from '@emotion/styled';
import { breakpoints, colors, fontFamilies } from '../baseStyle/Variables';

const FormNavButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormSubHeader = styled.p`
  text-align: left;
  font-size: 22px;
  text-align: center;

  @media (max-width: ${breakpoints.md}) {
    font-size: 18px;
  }
`;

const FormInputHeader = styled.p`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: -0.015em;
  margin-bottom: 0;
`;

const FormInputLabel = styled.label`
  font-family: ${fontFamilies.interRegular};
  margin: 0.5rem 0 0.3rem 0;
  font-size: 10px;
  color: ${colors.darkGray};
  font-weight: 600;
`;

const FormInputErrorText = styled.span`
  top: 5px;
  font-size: 0.75rem;
  line-height: 1.5rem;
  padding-left: 0.5rem;
  text-overflow: ellipsis;
  color: ${colors.errorRed};
`;

const FormFinePrint = styled.p`
  font-family: ${fontFamilies.interRegular};
  margin: 1rem 0;
  font-size: 0.88rem;
  color: ${colors.black};
  a {
    font-size: 0.88rem;
    color: ${colors.koalafiBlue};
    text-decoration: none;
    font-weight: 600;
  }
  b {
    font-family: ${fontFamilies.gilroySemiBold};
  }
  text-align: center;
`;

const Checkbox = styled.input`
  flex-shrink: 0;
  appearance: none;
  width: 1rem;
  height: 1rem;
  box-sizing: content-box;
  border: 1px solid ${colors.gray};
  border-radius: 50%;
  position: relative;

  &:checked {
    background: radial-gradient(${colors.koalafiBlue} 50%, ${colors.white} 55%);
  }
`;

export {
  FormNavButtonWrapper,
  FormFinePrint,
  FormInputErrorText,
  FormInputHeader,
  FormSubHeader,
  FormInputLabel,
  Checkbox,
};
