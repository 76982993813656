import { gql } from '@apollo/client';
import { apolloMutation, apolloQuery } from '../apollo';
import { NewOrder, UpdateOrderItemsInput } from '../gql/graphql';

export interface Orders {
  getOrderById(id: string): Promise<object>;
  createOrder(input: any): Promise<object>;
  updateOrderItems(input: UpdateOrderItemsInput): Promise<object>;
}

const orderService: Orders = {
  async getOrderById(id: string) {
    return apolloQuery(ORDER_QUERY, { id: id });
  },
  async createOrder(input: NewOrder) {
    return apolloMutation(createOrderMutation, { input: input });
  },
  async updateOrderItems(input: UpdateOrderItemsInput) {
    return apolloMutation(updateOrderItemsMutation, { input });
  },
};

export const ORDER_QUERY = gql`
  query order($id: ID!) {
    order(id: $id) {
      dealer {
        id
        integrationType
        financingType
        domains
        publicDealerId
        storeName
        friendlyName
        dealerImage
        isVirtualCard
        integrationDetails {
          integrationType
          platformName
        }
        ipBehavior
        formType
        channelType
        isAllowedToCreateNewApplications
        supportsExternalBankConnector
      }
      application {
        loan {
          id
          summary {
            id
            finalPaymentAmount
            paymentAmount
            firstPaymentDue
            annualPercentageRate
            financeCharge
            termLength
            amountFinanced
            loanAmount
            deferredInterestPeriod
          }
          achDetails {
            routingNumber
            accountNumberLast4
          }
          status
          isOtb
          maxLoanAmount
          cartValidationDetails
          options {
            productID
            deferredInterestPeriod
            lineAmount
            merchantDiscountRate
            minimumSpend
            rate
            termLength
          }
        }
        lease {
          id
          status
          declineReason
          decisionCode
          displayId
          publicId
          draftStep
          appStep
          isOtb
          maxLeaseAmount
          cartValidationDetails
          options {
            leaseTerm
            sacTerm
            earlyBuyoutDiscount
            approvedAmount
            applicationFee
            ipUpperBoundary
            minimumSpend
          }
          employment {
            payPeriod
            lastPayDate
            nextPayDate
            monthlyIncome
            isPaidViaDirectDeposit
          }
          card {
            isExpired
            provider
            lastFour
            expirationDate
          }
          bank {
            accountNumberLast4
            routingNumber
          }
          contractSummaries {
            leaseTerm
            leaseAmount
            cashPlusPrice
            cashPlusTerm
            cashPlusFee
            initialPaymentWithTax
            initialPaymentAmount
            paymentFrequency
            tax
            totalCostOfOwnership
            scheduledPaymentAmounts
          }
        }
        customer {
          id
          firstName
          lastName
          cellPhone
          emailAddress
          birthDate
          tokenizedTaxId
          billingAddress {
            line1
            line2
            city
            state
            zip
          }
          shippingAddress {
            line1
            line2
            city
            state
            zip
          }
        }
      }
      details {
        customer {
          firstName
          lastName
          emailAddress
          cellPhone
          billingAddress {
            line1
            line2
            city
            state
            zip
          }
          shippingAddress {
            line1
            line2
            city
            state
            zip
          }
        }
        items {
          category
          displayName
          quantity
          sku
          price
          itemUrl
          itemImageUrl
          isLeaseable
        }
        shippingAmnt
        taxAmnt
      }
      metadata {
        performBAV
      }
    }
  }
`;

const createOrderMutation = gql`
  mutation createOrder($input: NewOrder!) {
    createOrder(input: $input) {
      order {
        id
      }
      orderErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
      }
    }
  }
`;

const updateOrderItemsMutation = gql`
  mutation updateOrderItems($input: UpdateOrderItemsInput!) {
    updateOrderItems(input: $input) {
      order {
        details {
          items {
            category
            displayName
            quantity
            sku
            price
            itemUrl
            itemImageUrl
            isLeaseable
          }
          shippingAmnt
          taxAmnt
        }
      }
      orderErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
      }
    }
  }
`;

export default orderService;
