import { createMachine } from 'xstate';
import { Order } from '../gql/graphql';

export default createMachine(
  {
    id: 'VirtualCard',
    initial: 'Loading',
    context: {
      order: null,
    },
    states: {
      Loading: {
        // TODO: Should go to cart summary if resuming (has items in cart)
        // TODO: Should go to contract summary if docs_ready
        always: [
          {
            target: 'ContractSummary',
            cond: 'DocsReady',
          },
          {
            target: 'ItemEntry',
          },
        ],
      },
      ItemEntry: {
        on: {
          CANCEL: {
            target: 'CartSummary',
          },
          CONFIRM_ADD_ITEM: {
            target: 'CartSummary',
          },
        },
      },
      CartSummary: {
        on: {
          ADD_ITEM: {
            target: 'ItemEntry',
          },
          CONFIRM_ITEMS: {
            target: 'CartTotal',
          },
        },
      },
      CartTotal: {
        on: {
          BACK: {
            target: 'CartSummary',
          },
          CONFIRM_TOTAL: {
            target: 'ContractSummary',
          },
        },
      },
      ContractSummary: {
        on: {
          CONTINUE: {
            target: 'LeaseAgreement',
          },
          BACK: {
            target: 'CartSummary',
          },
        },
      },
      LeaseAgreement: {
        on: {
          BACK: {
            target: 'ContractSummary',
          },
          SIGN: {
            target: 'VirtualCardPage',
          },
        },
      },
      VirtualCardPage: {
        on: {
          VIEW_TERMS: {
            target: 'AuthorizedUserTerms',
          },
        },
      },
      AuthorizedUserTerms: {
        on: {
          BACK: {
            target: 'VirtualCardPage',
          },
        },
      },
    },
    schema: {
      context: {} as { order: Order | null },
      events: {} as
        | { type: 'CANCEL' }
        | { type: 'CONFIRM_ADD_ITEM' }
        | { type: 'ADD_ITEM' }
        | { type: 'CONFIRM_ITEMS' }
        | { type: 'BACK' }
        | { type: 'CONFIRM_TOTAL' }
        | { type: 'CONTINUE' }
        | { type: 'SIGN' }
        | { type: 'VIEW_TERMS' },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },

  {
    actions: {
      ItemEntryTestPrep: (context, event) => null,
    },
    guards: {
      DocsReady: (context, event) => context?.order?.application?.lease?.status === 'docsReady',
    },
  },
);
