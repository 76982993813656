import React from 'react';
import styled from '@emotion/styled';
import { AppMode } from '../../stores/order.store';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { colors, fontFamilies } from '../baseStyle/Variables';

const FooterStyle = styled.div`
  height: 2.25rem;
  width: 100%;
  background: ${colors.darkBlue};
  position: absolute;
  left: 0;
  bottom: 0;
`;

const FooterText = styled.div`
  font-family: ${fontFamilies.interSemiBold};
  font-size: 0.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
`;

const InsideStyle = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const Footer: React.FC = () => {
  const appMode = useStoreState((state) => state.order.appMode);
  if (appMode === AppMode.loan) {
    return (
      <FooterStyle>
        <FooterText>
          <InsideStyle>Loans issued by the Bank of Missouri, serviced by Koalafi</InsideStyle>
        </FooterText>
      </FooterStyle>
    );
  }
  return <div />;
};

export default Footer;
