/* istanbul ignore file */
const acceptedDomain = (acceptedDomains: string[]) => {
  const curDomain = currentDomain();
  return acceptedDomains?.find((domain) => {
    let santizedDomain = sanitizeString(domain);
    santizedDomain = removeTrailingSlash(santizedDomain);
    // Assuming that domain we have in db will always be same length if not substring of actual url
    return curDomain.includes(santizedDomain);
  });
};

const removeTrailingSlash = (url: string) => {
  let trimmedUrl = url;
  if (url.charAt(url.length - 1) === '/') {
    trimmedUrl = url.substring(0, url.length - 1);
  }
  return trimmedUrl;
};

const sanitizeString = (url: string) => {
  let sanitziedUrl = url;
  return sanitziedUrl.trim().toLowerCase();
};

const currentDomain = () => {
  // check to see if we have a parentDomain query param before trying to calculate it
  let parentDomainQueryParam = new URLSearchParams(document.location.search.substring(1)).get('parentDomain');
  if (parentDomainQueryParam) {
    parentDomainQueryParam = sanitizeString(parentDomainQueryParam);
    parentDomainQueryParam = removeTrailingSlash(parentDomainQueryParam);
    return parentDomainQueryParam;
  }

  const url = window.location !== window.parent.location ? document.referrer : window.location.href;
  const l = new URL(url);
  let sanitizedUrl = sanitizeString(l.origin);
  return removeTrailingSlash(sanitizedUrl);
};

// Can be used to check iframe's parent domain
const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export { acceptedDomain, inIframe, currentDomain };
