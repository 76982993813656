/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { inIframe } from '../../helpers';
import BaseStyle from '../baseStyle/BaseStyle';
import FullScreen from '../full-screen/full-screen';
import Modal from '../modal/modal';

interface ContainerProps {
  children: React.ReactNode;
}

const CommonContainer: React.FC<ContainerProps> = ({ children }) => {
  const isModal = inIframe();
  return (
    <BaseStyle>
      {isModal && <Modal>{children}</Modal>}
      {!isModal && <FullScreen>{children}</FullScreen>}
    </BaseStyle>
  );
};

export default CommonContainer;
