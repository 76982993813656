import { createTypedHooks } from 'easy-peasy';

import { AppModel } from '../stores/storeModel';

// provide model to helper
const typedHooks = createTypedHooks<AppModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;
