import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { ReactComponent as InfoIcon } from '../../assets/info_icon.svg';
import ReactTooltip, { Place } from 'react-tooltip';
import React from 'react';

const Tooltip: React.FC<{
  id: string;
  tooltipContent: React.ReactElement;
  focused?: boolean;
  size?: string;
  inline?: boolean;
  place?: Place;
  maxWidth?: string;
}> = ({ id, tooltipContent, focused, size, inline, place, maxWidth }) => {
  const SVGContainer = styled.div`
    position: ${inline ? 'relative' : 'absolute'};
    display: inline-flex;
    direction: column;
    align-items: center;
    justify-content: center;
    height: ${inline ? 'auto' : '2rem'};
    padding-left: ${inline ? '.1rem' : '0'};
    top: ${inline ? '2px' : '0'};
    right: 0;
    svg {
      path {
        fill: ${focused ? colors.koalafiBlue : colors.middleGrey};
      }
    }
  `;

  const StyledReactTooltip = styled(ReactTooltip)`
    max-width: ${() => maxWidth || '225px'};
    box-sizing: border-box;
    background-color: ${colors.white} !important;
    box-shadow: 0px 2px 6px ${colors.darkGray} !important;
    opacity: 1 !important;
    font-family: ${fontFamilies.interLight};
    padding: 0.8rem !important;
    &:after {
      border-top-color: ${colors.white} !important;
    }
  `;

  return (
    <SVGContainer
      data-tip="tooltip"
      data-event="click mouseenter focus"
      data-event-off="mouseleave blur"
      data-for={`${id}-tooltip`}
      tabIndex={0}
      aria-controls={`${id}-tooltip`}
      aria-label={`${id}`}
      role="tooltip"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          ReactTooltip.hide(e.currentTarget);
        }
      }}
    >
      <InfoIcon aria-hidden="true" height={size || '15px'} width={size || '15px'} />
      <StyledReactTooltip
        className="tooltip"
        id={`${id}-tooltip`}
        effect="solid"
        event="hover"
        type="light"
        globalEventOff="click"
        textColor={colors.darkBlue}
        place={place || 'top'}
        data-tooltip-offset={400}
        role="tooltip"
      >
        {tooltipContent}
      </StyledReactTooltip>
    </SVGContainer>
  );
};

export default Tooltip;
