import React from 'react';
import Table from '../../components/table';
import Tooltip from '../../components/tooltipV2/Tooltip';

type ContractSummaryTableProps = {
  retailPrice: string;
  tax: string;
  totalPurchaseAmount: string;
  timeToOwnership: string;
  paymentFrequency: string;
  numRemainingPayments: number;
  initialPayment: string;
  secondPayment: string;
  finalPayment: string | null;
  costOfLease: string | null;
  costOfOwnership: string;
  numSimilarPayments: number;
  cashPlusPrice: string;
  cashPlusTerm: { singular: string; plural: string };
  isVirtualCard: boolean;
};

const paymentFrequencyLabels: { [index: string]: string } = {
  weekly: 'Weekly',
  biweekly: 'Every two weeks',
  monthly: 'Monthly',
  semimonthly: 'Twice per month',
};

const RetailPriceTooltip = (
  <div>This is the cash purchase price of the items you are leasing plus shipping, and excluding tax.</div>
);
const SalesTaxTooltip = <div>This is the sales tax associated with your purchase amount.</div>;
const TotalPurchaseAmountTooltip = (
  <div>This is the cash purchase price of items you are leasing plus shipping, and including tax</div>
);
const TimetoOwnershipTooltip = (
  <div>Your initial lease term is less than 3 months. The lease term renews with each scheduled payment.</div>
);
const PaymentFrequencyTooltip = <div>Payments are set up to align with your income pay dates.</div>;
const RemainingPaymentsTooltip = <div>The number of payments you have left before you acquire ownership.</div>;
const InitialPaymentsTooltip = (
  <div>
    The initial payment (including tax) is in addition to your lease amount and any taxes and is counted as payment 1 in
    your schedule.
  </div>
);
const SecondPaymentsTooltip = (
  <div>Payments include lease amount, leasing costs and tax. Tax must be applied to all leasing costs.</div>
);
const FinalPaymentTooltip = (
  <div>Payments include lease amount, leasing costs and tax. Tax must be applied to all leasing costs.</div>
);
const CostOfLeaseTooltip = (cpt: string, cpp: string) => (
  <div>
    This is the amount you will pay on top of the cash price if you do not exercise your {cpt} {cpp} payment option or
    if you do not otherwise pay off early before your scheduled time to ownership. Applicable fees may increase this
    amount.
  </div>
);
const TotalOwnershipTooltip = (cpt: string, cpp: string) => (
  <div>
    You will pay this amount if you do not exercise your {cpt} {cpp} payment option or if you do not otherwise pay off
    early before your scheduled time to ownership. Applicable fees may increase this amount.
  </div>
);

const getLabel = (labelText: string, tooltipID: string, tooltipContent: any) => {
  return (
    <div>
      {labelText} <Tooltip id={tooltipID} tooltipContent={tooltipContent} inline={true} size="12px" />
    </div>
  );
};

const ContractSummaryTable: React.FC<ContractSummaryTableProps> = ({
  retailPrice,
  tax,
  totalPurchaseAmount,
  timeToOwnership,
  paymentFrequency,
  numRemainingPayments,
  initialPayment,
  secondPayment,
  finalPayment,
  costOfLease,
  costOfOwnership,
  numSimilarPayments,
  cashPlusPrice,
  cashPlusTerm,
  isVirtualCard,
}) => {
  const leaseRows = [
    ...(!isVirtualCard
      ? [
          {
            label: getLabel('Retail price', 'retailPrice', RetailPriceTooltip), //`Lease amount`,
            value: retailPrice,
          },
        ]
      : []),
    ...(!isVirtualCard
      ? [
          {
            label: getLabel('Sales tax', 'salesTax', SalesTaxTooltip), //`Lease amount`,
            value: tax,
          },
        ]
      : []),
    {
      label: getLabel('Total purchase amount', 'totalPurchaseAmount', TotalPurchaseAmountTooltip), //`Lease amount`,
      value: totalPurchaseAmount,
    },
    {
      label: getLabel('Scheduled time to ownership', 'timeToOwnership', TimetoOwnershipTooltip),
      value: timeToOwnership,
    },
    {
      label: getLabel('Payment frequency', 'paymentFrequency', PaymentFrequencyTooltip),
      value: paymentFrequencyLabels[paymentFrequency],
    },
    {
      label: getLabel(
        'Number of remaining scheduled payments to acquire ownership',
        'remainingPayments',
        RemainingPaymentsTooltip,
      ),
      value: numRemainingPayments,
    },
    {
      label: getLabel('Initial payment', 'initialPayment', InitialPaymentsTooltip),
      value: initialPayment,
    },

    {
      // TODO: ERK change payments 2 to be a variable.  Not always 2
      label: getLabel(`Payments 2 - ${numSimilarPayments}`, 'secondPayment', SecondPaymentsTooltip),
      value: secondPayment,
    },
    ...(finalPayment !== null
      ? [
          {
            label: getLabel(
              `Payment ${numSimilarPayments ? numSimilarPayments + 1 : ''}`,
              'finalPayment',
              FinalPaymentTooltip,
            ),
            value: finalPayment,
          },
        ]
      : []),

    ...(costOfLease !== null
      ? [
          {
            label: getLabel('Cost of lease', 'costOfLease', CostOfLeaseTooltip(cashPlusTerm.singular, cashPlusPrice)),
            value: costOfLease,
          },
        ]
      : []),
    {
      label: getLabel(
        `Scheduled total cost of ownership`,
        'totalCost',
        TotalOwnershipTooltip(cashPlusPrice, cashPlusTerm.singular),
      ),
      value: costOfOwnership,
      makeBold: true,
    },
  ];

  return <Table name="contract-summary-table" rows={leaseRows} />;
};

export default ContractSummaryTable;
