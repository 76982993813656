import React from 'react';
import styled from '@emotion/styled';
import { convertToCurrency } from '../../helpers/converters/converters';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { AppMode } from '../../stores/order.store';
import LeaseTermsTooltip from '../lease-terms-tooltip';
import LoanTermsTooltip from '../loans-terms-tooltip/loans-terms-tooltip';

const ApprovalAmount = ({ amount, isOTB }: { amount: string | undefined | null; isOTB?: boolean }) => {
  const { appMode } = useStoreState((state) => state.order);
  const approvalAmount = isOTB ? convertToCurrency(parseFloat(amount!)) : convertToCurrency(parseFloat(amount!), 0);

  const Amount = styled.div`
    font-family: ${fontFamilies.interBold};
    font-size: 1.35rem;
    color: ${colors.darkBlue};
    text-align: left;
  `;

  const ApprovedHeader = styled.div`
    font-family: ${fontFamilies.interLight};
    font-size: 1.1rem;
    color: ${colors.darkBlue};
    line-height: 125%;
    text-align: left;
    display: inline-block;
  `;

  const getQualifiedSubheader = () => {
    if (appMode === AppMode.lease) {
      return (
        <span>
          our <LeaseTermsTooltip styling={{ fontSize: '1.1rem' }} /> financing
        </span>
      );
    } else {
      return (
        <span>
          a <LoanTermsTooltip styling={{ fontSize: '1.1rem' }} />
        </span>
      );
    }
  };

  return (
    <div>
      <ApprovedHeader>
        <span>You're prequalified for </span> {getQualifiedSubheader()} up to
      </ApprovedHeader>
      <Amount>{approvalAmount}</Amount>
    </div>
  );
};

export default ApprovalAmount;
