import { v4 as uuidv4 } from 'uuid';

const generateSessionId = () => {
  const newSessID = uuidv4();
  return newSessID;
};

const generateCorrelationId = () => {
  const newCorrId = uuidv4();
  return newCorrId;
};

const setSessionId = async () => {
  let sessionId = sessionStorage.getItem('sessionId');

  if (sessionId === null || sessionId === '') {
    const genId = generateSessionId();
    sessionId = genId;
    sessionStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
};

export { generateSessionId, generateCorrelationId, setSessionId };
