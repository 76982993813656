import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { ReactComponent as WallySunglassesImage } from '../../assets/Wally_Sunglasses.svg';
import { convertToCurrency } from '../../helpers/converters/converters';
import LeaseTermsTooltip from '../lease-terms-tooltip/lease-terms-tooltip';

type WallyPreApprovalProps = {
  financingAmount: string | undefined;
};

const PreApprovalContainerStyle = styled.div`
  position: relative;
`;

const WallyStyle = styled.div`
  position: absolute;
  width: 5rem;
  right: 2rem;
  bottom: -2.4rem;
  z-index: 1; // without this, the gradient overlaps wally's feet
`;

const PreApprovalText = styled.div<{ prequal?: boolean }>`
  color: ${colors.darkBlue};
  font-family: ${fontFamilies.gilroyExtraBold};
  font-size: ${(props: any) => (props.prequal ? '3.5rem' : '4rem')};
  line-height: 0.9;
  text-align: left;
  hyphens: none;
`;

const PreApprovalSubText = styled.div`
  color: ${colors.darkBlue};
  font-family: ${fontFamilies.interLight};
  font-size: 1.1rem;
  text-align: left;
  margin-top: 1rem;
`;

const CurrencyStyle = styled.div`
  font-family: ${fontFamilies.interBold};
  line-height: 1.4;
  font-size: 1.75rem;
`;

const PreApprovedSpan = styled.span`
  white-space: nowrap;
`;

const WallyPreApproval: React.FC<WallyPreApprovalProps> = ({ financingAmount }: WallyPreApprovalProps) => {
  return (
    <PreApprovalContainerStyle>
      <WallyStyle>
        <WallySunglassesImage tabIndex={-1} />
      </WallyStyle>
      <PreApprovalText prequal={true}>
        You're <PreApprovedSpan>pre-approved</PreApprovedSpan>
      </PreApprovalText>
      <PreApprovalSubText>
        <React.Fragment>
          <div>
            for our <LeaseTermsTooltip styling={{ fontSize: '1.1rem' }} />
          </div>
          <div>financing up to</div>
        </React.Fragment>
        <CurrencyStyle>{convertToCurrency(parseFloat(financingAmount!), 0)}</CurrencyStyle>
      </PreApprovalSubText>
    </PreApprovalContainerStyle>
  );
};

export default WallyPreApproval;
