import styled from '@emotion/styled';
import React from 'react';
import { ItemInput } from '../../gql/graphql';
import { colors, fontFamilies } from '../baseStyle/Variables';

type Props = {
  items: ItemInput[];
};

const Container = styled.div`
  text-align: center;
  position: relative;
`;

const CartItemList = styled.ul`
  padding-left: 1.5rem;
  margin: 0.5rem 0;

  li {
    color: ${colors.darkBlue};
    font-family: ${fontFamilies.interLight};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0 0 0.1rem;
  }

  li::marker {
    color: ${colors.darkBlue};
  }
`;

const ListItems: React.FC<Props> = ({ items }: Props) => {
  const displayNames = items.map((item) => <li>{item.displayName}</li>);

  const itemList = <CartItemList>{displayNames}</CartItemList>;

  return <Container>{itemList}</Container>;
};

export default ListItems;
