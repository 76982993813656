import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';

type RowItem = {
  label: any;
  value: any;
  makeBold?: boolean;
};

type TableProps = {
  name: string;
  rows: RowItem[];
  showBottomBorder?: boolean;
};

const TableWrapper = styled.div<{ showBottomBorder: boolean }>`
  div:last-child {
    border-bottom: ${(props: any) => (props.showBottomBorder ? `0.5px solid ${colors.darkBlue}` : 'none')};
  }
`;

const RowWrapper = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${colors.darkBlue};
  font-family: ${fontFamilies.interLight};
  font-size: 0.8rem;
  color: ${colors.darkBlue};
  div:last-child {
    border-bottom: ${(props: any) => (props.showBottomBorder ? `0.5px solid ${colors.darkBlue}` : 'none')};
  }
`;

const Label = styled.div`
  width: 65%;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;

const Value = styled.div<{ makeBold: boolean }>`
  font-family: ${(props: any) => (props.makeBold ? fontFamilies.interBold : fontFamilies.interLight)};
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  margin-left: auto;
  text-align: end;
`;

const Table: React.FC<TableProps> = ({ rows, name, showBottomBorder = false }) => {
  return (
    <TableWrapper showBottomBorder={showBottomBorder}>
      {rows.map(({ label, value, makeBold = false }, index) => {
        return (
          <RowWrapper key={`${name}-row-${index}`}>
            <Label key={`label-${label}`}>{label}</Label>
            <Value key={`value-${label}`} makeBold={makeBold}>
              {value}
            </Value>
          </RowWrapper>
        );
      })}
    </TableWrapper>
  );
};

export default Table;

// TODO:ERK
// Need an option value to show or not show line on bottom row of table
// Need an optional prop to make the text bold
// Need to edit this table so that there is always space between left side and right (if the text is too long, etc...)
