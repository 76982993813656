import { PropertyMap } from '@dynatrace/dtrum-api-types';

function useSessionProperies() {
  const sessionProerties = (payload: PropertyMap<string>) => {
    // hard coding support of only string types here.  can always refactor if we need to support some
    // other type in the future
    window.dtrum?.sendSessionProperties(undefined, undefined, payload);
  };

  return sessionProerties;
}

function useTagUser() {
  const tagUser = (user: string) => {
    // set dynatrace user tag as email address
    window.dtrum?.identifyUser(user);
  };

  return tagUser;
}

function useReportError() {
  const reportError = (error: string | Error) => {
    // send the error up to DT manually
    window.dtrum?.reportError(error);
  };

  return reportError;
}

export { useSessionProperies, useTagUser, useReportError };
