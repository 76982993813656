import React from 'react';
import styled from '@emotion/styled';
import CloseButton from './CloseButton';
import BackButton from './BackButton';
import { inIframe } from '../../helpers';
import KoalafiLogo from '../koalafiLogo/KoalafiLogo';
import ProgressBar from '../progress-bar';
import { isStoreHP } from '../../helpers/merchants/merchants';

interface IProps {
  goBackFunc: undefined | (() => void);
  currentNode: [string] | undefined;
  dealerId?: number;
}

type StyleProps = {
  dealerId?: number;
};

const HeaderContainer = styled.div``;
const HeaderGradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
  height: 12px;
`;

// Again, not great this is hardcoded, but only want to change padding for HP logo
const HeaderComponents = styled.div<StyleProps>`
  margin: ${(props: any) => {
    return isStoreHP(props.dealerId) ? '0px' : '0.8rem 0px 0.5rem 0px';
  }};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonPlaceholder = styled.div`
  display: flex;
  appearance: none;
  width: 48px;
  height: 48px;
`;

const Header: React.FC<IProps> = ({ goBackFunc, currentNode, dealerId }) => {
  return (
    <HeaderContainer>
      <HeaderGradient />
      <HeaderComponents dealerId={dealerId}>
        {goBackFunc ? <BackButton handleClick={goBackFunc} /> : <ButtonPlaceholder />}
        <KoalafiLogo dealerId={dealerId} />
        {inIframe() ? <CloseButton /> : <ButtonPlaceholder />}
      </HeaderComponents>
      <ProgressBar currentNode={currentNode} />
    </HeaderContainer>
  );
};

export default React.memo(Header);
