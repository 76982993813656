import React, { useEffect, useState } from 'react';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { convertToCurrency } from '../../helpers/converters/converters';
import { AppMode } from '../../stores/order.store';
import ListItems from '../listItems/ListItems';
import { useTrack } from '../../hooks/segment';
import { CartValidationTypes } from '../../gql/graphql';
import { NON_LEASABLE_MULTIPLIER, NON_LEASABLE_PERCENTAGE } from '../../constants/cart-validation';

interface IProps {
  pageAppMode: AppMode | null | undefined;
  cartValidationErr: CartValidationTypes;
  approvalAmount: string;
  minimumSpend: number | null;
}

const CartValidationErrContent: React.FC<IProps> = ({
  pageAppMode,
  cartValidationErr,
  approvalAmount,
  minimumSpend,
}) => {
  const [cartDoesNotMeetMin, setCartDoesNotMeetMin] = useState(false);
  const [cartGreaterThanApproval, setCartGreaterThanApproval] = useState(false);
  const [tooManyNonLeaseableItems, setTooManyNonLeaseableItems] = useState(false);
  const { totalCartAmount } = useStoreState((state) => state.order);
  const track = useTrack();

  useEffect(() => {
    switch (cartValidationErr) {
      case 'CartTotalExceedsApproval':
        track('Application Approved with cart total greater than approval amount', {
          approvalAmount: approvalAmount,
          cartTotal: totalCartAmount,
          minimumSpend: minimumSpend,
        });
        setCartGreaterThanApproval(true);
        break;
      case 'CartTotalDoesNotMeetMinimum':
        track(
          `Application Approved with cart total less than required minimum (${convertToCurrency(minimumSpend ?? 0)}`,
          {
            approvalAmount: approvalAmount,
            cartTotal: totalCartAmount,
            minimumSpend: minimumSpend,
          },
        );
        setCartDoesNotMeetMin(true);
        break;
      case 'NonLeaseableItemsTotal':
        track('Application Approved with too many nonLeaseable Items in Cart', {
          approvalAmount: approvalAmount,
          cartTotal: totalCartAmount,
          minimumSpend: minimumSpend,
        });
        setTooManyNonLeaseableItems(true);
        break;
      default:
        break;
    }
  }, [minimumSpend, approvalAmount, totalCartAmount, track, cartValidationErr]);

  const CartTooHighComponent = () => {
    return (
      <h6>
        Your cart total of {convertToCurrency(totalCartAmount)} is larger than your prequalification amount of{' '}
        {convertToCurrency(parseInt(approvalAmount), 0)}. Please remove items from your cart to be able to checkout with
        Koalafi.
      </h6>
    );
  };

  const CartDoesNotMeetMinComponent = () => {
    return (
      <h6>
        Your cart total of {convertToCurrency(totalCartAmount)} is below the minimum amount of{' '}
        {convertToCurrency(minimumSpend ?? 0)}. Please add items to your cart to be able to checkout with Koalafi.
      </h6>
    );
  };

  const nonLeaseableItems = useStoreState((state) => state.order.nonLeasableItems);

  const CartContainsTooManyNonLeasableComponent = () => {
    return (
      <div>
        <h6 style={{ marginBottom: '0.25rem' }}>
          You must adjust your cart to be able to checkout with Koalafi, the following items must make up at most{' '}
          {NON_LEASABLE_PERCENTAGE} ({convertToCurrency(totalCartAmount * NON_LEASABLE_MULTIPLIER)}) of your cart total
          ({convertToCurrency(totalCartAmount)}):
        </h6>
        <ListItems items={nonLeaseableItems} />
      </div>
    );
  };
  return (
    <div style={{ marginTop: '2rem' }}>
      {cartGreaterThanApproval && <CartTooHighComponent />}

      {cartDoesNotMeetMin && <CartDoesNotMeetMinComponent />}

      {tooManyNonLeaseableItems && <CartContainsTooManyNonLeasableComponent />}
    </div>
  );
};

export default CartValidationErrContent;
