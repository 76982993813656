/* istanbul ignore file */

import styled from '@emotion/styled';
import { colors, fontFamilies, fontSizes } from '../baseStyle/Variables';

const BaseStyle = styled.div`
  font-family: ${fontFamilies.gilroySemiBold};
  font-size: ${fontSizes.regular};
  font-weight: 400;

  h1 {
    color: ${colors.darkBlue};
    font-family: ${fontFamilies.gilroyExtraBold};
    font-size: 2.25rem;
    line-height: 110%;
    text-align: left;
    margin: 0;
  }

  h2 {
    color: ${colors.darkBlue};
    font-family: ${fontFamilies.gilroyBold};
    font-size: 1.75rem;
    line-height: 125%;
    text-align: left;
    margin: 0;
  }

  h3 {
    color: ${colors.darkBlue};
    font-family: ${fontFamilies.interRegular};
    font-size: 1rem;
    line-height: 125%;
    font-weight: 400;
    text-align: left;
    margin: 0;

    & strong,
    & a {
      font-family: ${fontFamilies.interSemiBold};
    }
  }

  h6 {
    color: ${colors.darkBlue};
    font-family: ${fontFamilies.interLight};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 1rem 0;
    text-align: left;

    & strong,
    & a {
      font-family: ${fontFamilies.interSemiBold};
    }
  }

  p,
  li {
    color: ${colors.darkBlue};
    font-family: ${fontFamilies.interLight};
    font-size: 0.75rem;
    line-height: 125%;
    text-align: left;
    margin: 0 0 0.75rem 0;

    & strong,
    & a {
      font-family: ${fontFamilies.interSemiBold};
    }
  }

  a {
    color: ${colors.koalafiBlue};
    text-decoration: underline;
    cursor: pointer;
  }

  input:disabled {
    background-color: white;
    color: ${colors.gray};
  }
`;

export default BaseStyle;
