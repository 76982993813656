import { gql } from '@apollo/client';
import { apolloQuery } from '../apollo';

export interface FeatureFlags {
  getFeatureFlags(): Promise<object>;
  highestSupportedApplicationMilestone(dealerId: Number): Promise<object>;
}

const featureFlagService: FeatureFlags = {
  async getFeatureFlags() {
    return apolloQuery(FEATURE_FLAG_QUERY);
  },
  async highestSupportedApplicationMilestone(dealerId: Number) {
    return apolloQuery(highestSupportedApplicationMilestone, { dealerId });
  },
};

export const FEATURE_FLAG_QUERY = gql`
  query featureFlag {
    featureFlags {
      name
      enabled
    }
  }
`;

const highestSupportedApplicationMilestone = gql`
  query highestSupportedApplicationMilestone($dealerId: Int!) {
    highestSupportedApplicationMilestone(dealerId: $dealerId)
  }
`;

export default featureFlagService;
