import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { colors } from '../baseStyle/Variables';

const Bounce = keyframes`
  0% {
    top: calc(100% - 0.6rem);
  }
  33.333% {
    top: 0%;
  }
  66.667% {
    top: calc(100% - 0.6rem);
  }
  100% {
    top: calc(100% - 0.6rem);
  }
`;

const Dots = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  width: 2.4rem;
  height: 1.25rem;
  transform: scale(${(props: any) => props.scale || '1'});
`;

const Dot = styled.div<Props>`
  position: relative;
  bottom: 0;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: ${(props: any) => props.color || colors.koalafiBlue};
  animation: ${Bounce} 1s infinite ease-in-out;
`;

interface Props {
  color?: string;
  scale?: string;
}

const DotLoader: React.FC<Props> = ({ color, scale }) => (
  <Dots scale={scale}>
    <Dot color={color} />
    <Dot color={color} style={{ animationDelay: '.1s' }} />
    <Dot color={color} style={{ animationDelay: '.2s' }} />
  </Dots>
);

export default DotLoader;
