// Data converters for presentation to the user

// Add commas to numbers or strings: "1000" -> "1,000"
export const convertToCurrency = (value: number | string, fractionDigits = 2) => {
  let numberValue: number;
  if (typeof value === 'string') {
    numberValue = parseFloat(value);
  } else {
    numberValue = value;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return formatter.format(numberValue);
};
