import React from 'react';
import styled from '@emotion/styled';
import Loader from '../loader/Loader';

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 700px;
  height: 100%;
  justify-content: center;
`;

const PageLoader = () => {
  return (
    <LoaderWrapper>
      <Loader source="page" />
    </LoaderWrapper>
  );
};

export default PageLoader;
