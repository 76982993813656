const isStoreHP = (dealerId?: number) => {
  const hostname = window?.location?.hostname;

  return (
    ((hostname.includes('sandbox') || hostname.includes('stage')) && dealerId === 11437) ||
    (hostname === 'epply.koalafi.com' && dealerId === 61255)
  );
};

export { isStoreHP };
