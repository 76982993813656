import applicationForm from './applicationForm.store';
import order from './order.store';
import auth from './auth.store';
import loan from './loan.store';
import featureFlags from './global.store';
import virtualCard from './virtualCard.store';
import { AppModel } from './storeModel';

const model: AppModel = {
  applicationForm,
  order,
  auth,
  loan,
  featureFlags,
  virtualCard,
};

export default model;
