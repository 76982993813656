import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { RemoveVirtualCardErrors, RemoveVirtualCardInput } from '../gql/graphql';
import { createForbiddenErrorMessage, createUnauthorizedErrorMessage } from '../helpers/security/security';
import { AppModel } from './storeModel';

export class EmbedUrlError extends Error {
  retriable: boolean;
  constructor(message: string, retriable: boolean) {
    super(message);
    this.retriable = retriable;
  }
}

export interface CartItemModel {
  itemName: string;
  price: number;
  quantity: number;
}

export interface EditCartItemModel {
  index: number;
  item: CartItemModel;
}

export interface VirtualCardModel {
  cart: CartItemModel[];
  approvalAmount: number;
  setApprovalAmount: Action<VirtualCardModel, number>;

  cartTotal: Computed<VirtualCardModel, number>;
  hasItems: Computed<VirtualCardModel, boolean>;
  remainingApprovalAmount: Computed<VirtualCardModel, number>;

  addToCart: Action<VirtualCardModel, CartItemModel>;
  deleteFromCart: Action<VirtualCardModel, number>;

  itemToEdit: EditCartItemModel | null;
  setItemToEdit: Action<VirtualCardModel, EditCartItemModel | null>;
  updateItem: Action<VirtualCardModel, CartItemModel>;

  embedUrl: string;
  cardExpiration: number;
  embedUrlError: EmbedUrlError | null;
  setEmbedUrl: Action<VirtualCardModel, string>;
  setCardExpiration: Action<VirtualCardModel, number>;
  setEmbedUrlError: Action<VirtualCardModel, EmbedUrlError | null>;
  queryEmbedUrl: Thunk<VirtualCardModel, string, any, AppModel>;

  removeVirtualCard: Thunk<VirtualCardModel, RemoveVirtualCardInput, any, AppModel>;
  removeVirtualCardErrors: RemoveVirtualCardErrors[];
  hasAuthError: Computed<VirtualCardModel, boolean>;
  setRemoveVirtualCardErrors: Action<VirtualCardModel, RemoveVirtualCardErrors[]>;
  removeVirtualCardResult: boolean; // used to indicate that the request has completed
  setRemoveVirtualCardResult: Action<VirtualCardModel, boolean>;
}
const virtualCard: VirtualCardModel = {
  cart: [],
  approvalAmount: 0,
  setApprovalAmount: action((state, payload) => {
    state.approvalAmount = payload;
  }),

  cartTotal: computed(
    (state) => state.cart.reduce((total, cartItem) => total + Math.round(cartItem.price * 100), 0) / 100,
  ),
  hasItems: computed((state) => state.cart.length > 0),
  remainingApprovalAmount: computed(
    (state) => (Math.round(state.approvalAmount * 100) - Math.round(state.cartTotal * 100)) / 100,
  ),

  addToCart: action((state, payload) => {
    state.cart.push(payload);
  }),
  deleteFromCart: action((state, payload) => {
    state.cart.splice(payload, 1);
  }),

  itemToEdit: null,
  setItemToEdit: action((state, payload) => {
    state.itemToEdit = payload;
  }),
  updateItem: action((state, payload) => {
    if (state.itemToEdit) {
      state.cart[state.itemToEdit.index] = payload;
    }
  }),

  embedUrl: '',
  cardExpiration: 0,
  embedUrlError: null,
  setEmbedUrl: action((state, payload) => {
    state.embedUrl = payload;
  }),
  setCardExpiration: action((state, payload) => {
    state.cardExpiration = payload;
  }),
  setEmbedUrlError: action((state, payload) => {
    state.embedUrlError = payload;
  }),
  queryEmbedUrl: thunk(async (actions, orderId, { injections, getStoreActions }) => {
    const { virtualCardService } = injections;
    const authActions = getStoreActions().auth;

    try {
      const result = await virtualCardService.getEmbedUrlById(orderId);
      if (result.data.generateEmbeddedVirtualCardRequest.generateEmbeddedVirtualCardRequestErrors.length > 0) {
        // just treat all errors from AES as retriable for now
        actions.setEmbedUrlError(new EmbedUrlError('embed url generation failed', true));
      } else {
        actions.setEmbedUrl(result.data.generateEmbeddedVirtualCardRequest.embeddedVirtualCardRequest);
        actions.setCardExpiration(result.data.generateEmbeddedVirtualCardRequest.cardExpiration);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          return forbiddenErrMsg;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          return unauthErrMsg;
        default:
          actions.setEmbedUrlError(new EmbedUrlError('could not retrieve embedUrl from AES', true));
      }
    }
  }),

  removeVirtualCardErrors: [],
  setRemoveVirtualCardErrors: action((state, payload) => {
    state.removeVirtualCardErrors = payload;
  }),

  removeVirtualCardResult: false,
  setRemoveVirtualCardResult: action((state, payload) => {
    state.removeVirtualCardResult = payload;
  }),

  removeVirtualCard: thunk(async (actions, input, { injections, getStoreActions }) => {
    const { applicationFormService } = injections;
    const authActions = getStoreActions().auth;

    actions.setRemoveVirtualCardResult(false);
    try {
      const response = await applicationFormService.removeVirtualCard(input);
      if (response?.data?.removeVirtualCard.removeVirtualCardErrors.length > 0) {
        actions.setRemoveVirtualCardErrors(response?.data?.removeVirtualCard.removeVirtualCardErrors);
      }
      actions.setRemoveVirtualCardResult(true); // This needs to be last because it's the top level trigger for ui rerenders
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          return forbiddenErrMsg;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          return unauthErrMsg;
        default:
          // TODO - confirm we don't want to set error general here?
          console.error(err);
      }
    }
  }),

  hasAuthError: computed((state) => {
    const hasAuthError = state.removeVirtualCardErrors.find(
      (err: RemoveVirtualCardErrors) => err.__typename === 'HasAuthError',
    );

    return !!hasAuthError;
  }),
};

export default virtualCard;
