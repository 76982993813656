import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';

export const FloatingLabel = styled.label<{ focused: boolean; valid: boolean; empty: boolean }>`
  position: absolute;
  line-height: 2rem;
  top: ${(props: any) => (props.focused || !props.empty ? '-1.75rem' : '0')};
  font-family: ${fontFamilies.interLight};
  font-size: 0.875rem;
  color: ${(props: any) => (props.focused ? colors.koalafiBlue : props.valid ? colors.middleGrey : colors.errorRed)};
  cursor: text;
  transition: all 0.5s;
`;

export const ValidationError = styled.span`
  display: flex;
  width: 100%;
  font-size: 0.75rem;
  margin: 0.15rem 0;
  line-height: 1rem;
  color: ${colors.errorRed};
  transition: all 0.5s;

  &.hidden {
    opacity: 0;
    max-height: 0;
  }

  &.visible {
    opacity: 1;
    max-height: 5rem; // This is a guess at our longest possible error message
  }
`;
