import { AppConfig } from './app.config';

declare global {
  interface Window {
    analytics: any;
    _fs_is_outer_script: boolean;
  }
}

/**
 * This script below is provided by Segment - access at app.segment.com
 * It has been slightly updated to provide typescript types where required
 */
(function () {
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
    else {
      // line below added to set full story recording env variable, before segment loads FS
      // see scenario 4: https://help.fullstory.com/hc/en-us/articles/360020622514-Can-FullStory-capture-content-that-is-presented-in-iframes-
      window['_fs_is_outer_script'] = true;

      // segment script continues below....
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e: any) {
        return function () {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      try {
        analytics.load = function (key: any, e: any) {
          var t = document.createElement('script');
          t.type = 'text/javascript';
          t.crossOrigin = 'anonymous';
          t.async = !0;
          t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
          var n: any = document.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.13.1';
        analytics.load(AppConfig.segmentWriteKey);
        // block is to set anonymousId as orderId for segment.
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const orderId = urlParams.get('orderId');
        if (orderId) {
          analytics.setAnonymousId(orderId);
        }
      } catch (e) {
        console.error(`Setting anonymousId failed: ${e}`);
      }

      analytics.page();
    }
})();
