import { action, Action, Thunk, thunk, Computed, computed } from 'easy-peasy';
import {
  AutopayErrors,
  AutopayInput,
  ContractDetails,
  LoanDocuments,
  LoanDocumentsErrors,
  LoanOptionErrors,
  LoanOptions,
  Option,
  SignLoanAgreementInput,
  SigningErrors,
  VerifyRoutingErrors,
} from '../gql/graphql';
import { ErrorMessage } from './applicationForm.store';
import { selectLoanHelper } from '../helpers';
import { selectLoanHelperOTB } from '../helpers/loans/loans.helpers';
import { createForbiddenErrorMessage, createUnauthorizedErrorMessage } from '../helpers/security/security';
import { AppModel } from './storeModel';

export interface LoanModel {
  // Loan Docs
  getLoanDocs: Thunk<LoanModel, string, any, AppModel>;
  setLoanDocs: Action<LoanModel, LoanDocuments>;
  setLoanDocsError: Action<LoanModel, LoanDocumentsErrors[]>;
  loanDocsError: LoanDocumentsErrors[];
  loanDocs: null | LoanDocuments;

  // Get Bank Name
  getBankName: Thunk<LoanModel, string, any, AppModel>;
  setBankName: Action<LoanModel, string | undefined>;
  setBankNameError: Action<LoanModel, VerifyRoutingErrors[]>;
  bankNameError: VerifyRoutingErrors[];
  bankName: string;

  // Select Loan Option Type
  selectLoanOptionType: Thunk<LoanModel, string, any, AppModel>;
  setSelectLoanOptionResponse: Action<LoanModel, ContractDetails>;
  setSelectLoanOptionError: Action<LoanModel, LoanOptionErrors[]>;
  selectLoanOptionResponse: ContractDetails | null;
  selectLoanOptionError: LoanOptionErrors[];

  // Selected Loan
  selectedLoan: Computed<LoanModel, LoanOptions | null>;

  // General form error
  error?: ErrorMessage | null;
  setErrorGeneral: Action<LoanModel, any>;

  // loading form action
  isLoading: boolean;
  setIsLoading: Action<LoanModel, boolean>;

  // LOAN AGREEMENTS
  signLoanAgreement: Thunk<LoanModel, SignLoanAgreementInput, any, AppModel>;
  loanAgreementSigned: boolean;
  setLoanAgreementSigned: Action<LoanModel, boolean>;
  signLoanAgreementErrors: SigningErrors[];
  setSignLoanAgreementErrors: Action<LoanModel, SigningErrors[]>;

  // AUTO PAY ENROLLMENT
  enrollInAutoPay: Thunk<LoanModel, AutopayInput, any, AppModel>;
  isEnrolledInAutoPay: boolean;
  setIsEnrolledInAutoPay: Action<LoanModel, boolean>;
  enrollInAutoPayErrors: AutopayErrors[];
  setEnrollInAutoPayErrors: Action<LoanModel, AutopayErrors[]>;
}

const loan: LoanModel = {
  // state
  loanDocs: null,
  loanDocsError: [],
  bankName: '',
  bankNameError: [],
  selectLoanOptionResponse: null,
  selectLoanOptionError: [],
  signLoanAgreementErrors: [],
  enrollInAutoPayErrors: [],

  /**
   * LOADING: GET/SET
   */
  isLoading: false,
  // set isLoading app action
  setIsLoading: action((state, payload?) => {
    state.isLoading = payload ?? false;
  }),

  // This computed function sets which Loan Option the customer is using. This is in lieu of a product
  // selection screen, which we are not making at this time.
  selectedLoan: computed(
    [
      // storeState has access to all stores in the app
      (_, storeState: any) => {
        // TODO return selected loan if exists, this will require adding selectedProductId to GraphQL schema
        const loanOpts: Option[] | undefined = storeState?.order?.order?.application?.loan?.options;
        if (storeState?.order?.order?.application?.loan?.isOtb) {
          return selectLoanHelperOTB(loanOpts);
        } else {
          return selectLoanHelper(loanOpts);
        }
      },
    ],
    // this takes the output from the first function and sets it as the value
    (selectedLoan) => selectedLoan,
  ),
  setErrorGeneral: action((state, payload?) => {
    state.error = payload;
  }),

  // actions
  setLoanDocs: action((state, payload?) => {
    state.loanDocs = payload ?? null;
  }),

  setLoanDocsError: action((state, payload: LoanDocumentsErrors[]) => {
    state.loanDocsError = payload;
  }),

  setBankName: action((state, payload?) => {
    state.bankName = payload ?? '';
  }),

  setBankNameError: action((state, payload: VerifyRoutingErrors[]) => {
    state.bankNameError = payload;
  }),

  setSelectLoanOptionResponse: action((state, payload: ContractDetails) => {
    state.selectLoanOptionResponse = payload;
  }),

  setSelectLoanOptionError: action((state, payload: LoanOptionErrors[]) => {
    state.selectLoanOptionError = payload;
  }),

  // thunks
  getLoanDocs: thunk(async (actions, orderId, { injections, getStoreActions }) => {
    const { loansService } = injections;
    const authActions = getStoreActions().auth;

    try {
      const response = await loansService.getLoanDocs(orderId);
      if (response.data.generateLoanContract.loanDocumentsErrors.length > 0) {
        actions.setLoanDocsError(response.data.generateLoanContract.loanDocumentsErrors);
      } else {
        actions.setLoanDocs(response.data.generateLoanContract.loanDocuments);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
  }),

  getBankName: thunk(async (actions, routingNumber, { injections }) => {
    const { loansService } = injections;
    const response = await loansService.getBankName(routingNumber);
    if (response.data.verifyRouting.verifyRoutingErrors.length === 0) {
      actions.setBankName(response.data.verifyRouting.bankName);
    } else {
      // actions.setBankName('');
      actions.setBankNameError(response.data.verifyRouting.verifyRoutingErrors);
    }
  }),

  selectLoanOptionType: thunk(async (actions, orderId, helpers: any) => {
    const { loansService } = helpers.injections;
    const authActions = helpers.getStoreActions().auth;

    const selectedLoan = helpers.getState().selectedLoan;
    let productId;

    if (selectedLoan) {
      productId = selectedLoan.productID;
    } else {
      // not sure why this else block is needed, seems to do the same thing as the selectedLoan computed
      const allStoreState = helpers.getStoreState();

      let loanSelectedProd;
      if (allStoreState.applicationForm.application.loan.isOtb) {
        loanSelectedProd = selectLoanHelperOTB(allStoreState.applicationForm.application.loan.options);
      } else {
        loanSelectedProd = selectLoanHelper(allStoreState.applicationForm.application.loan.options);
      }

      productId = loanSelectedProd.productID;
    }
    try {
      const resp = await loansService.selectLoanOptionType({
        orderId,
        productId,
      });

      if (resp.data.selectLoanOptionType.loanOptionErrors.length > 0) {
        actions.setSelectLoanOptionError(resp.data.selectLoanOptionType.loanOptionErrors);
      } else {
        actions.setSelectLoanOptionResponse(resp.data.selectLoanOptionType.loanSummary);
        //We want to update the order.application.loan.summary with the loan summary retrieved from this call
        const { setSummary } = helpers.getStoreActions().order;
        setSummary(resp.data.selectLoanOptionType.loanSummary);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
  }),

  loanAgreementSigned: false,
  setLoanAgreementSigned: action((state, payload: boolean) => {
    state.loanAgreementSigned = payload;
  }),
  signLoanAgreement: thunk(async (actions, input: SignLoanAgreementInput, { injections, getStoreActions }) => {
    const { loansService } = injections;
    const authActions = getStoreActions().auth;

    actions.setSignLoanAgreementErrors([]);

    try {
      let response = await loansService.signLoanAgreement(input);
      if (response?.data?.signLoanAgreement.signingErrors.length === 0) {
        actions.setLoanAgreementSigned(true);
      }

      actions.setSignLoanAgreementErrors(response?.data?.signLoanAgreement.signingErrors);
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral({ displayText: 'Unable to sign loan agreement' });
          console.error(err);
      }
    }
  }),
  setSignLoanAgreementErrors: action((state, payload?) => {
    state.signLoanAgreementErrors = payload ?? [];
  }),
  isEnrolledInAutoPay: false,
  enrollInAutoPay: thunk(async (actions, input: AutopayInput, { injections, getStoreActions }) => {
    const { loansService } = injections;
    const authActions = getStoreActions().auth;

    actions.setIsLoading(true);
    actions.setEnrollInAutoPayErrors([]);

    try {
      let response = await loansService.enrollInAutopay(input);
      if (response?.data?.enrollInAutopay.autopayErrors.length === 0) {
        actions.setIsEnrolledInAutoPay(true);
      } else {
        actions.setEnrollInAutoPayErrors(response?.data?.enrollInAutopay.autopayErrors);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral({ displayText: 'Unable to enroll in auto pay' });
          console.error(err);
      }
    }

    actions.setIsLoading(false);
  }),
  setEnrollInAutoPayErrors: action((state, payload?) => {
    state.enrollInAutoPayErrors = payload ?? [];
  }),
  setIsEnrolledInAutoPay: action((state, payload) => {
    state.isEnrolledInAutoPay = payload;
  }),
};

export default loan;
