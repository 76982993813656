/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import lottie from 'lottie-web/build/player/lottie_light.js';
import KoalafiLoader from '../../assets/lotus_loader.json';

interface KoalafiInitialLoaderProps {}

const KoalafiInitialLoader: React.FC<KoalafiInitialLoaderProps> = () => {
  React.useEffect(() => {
    let loaderAnimation = lottie.loadAnimation({
      // @ts-ignore
      container: document.querySelector('#koalafi-initial-loader-ui'),
      animationData: KoalafiLoader,
      direction: 1,
    });

    loaderAnimation.addEventListener('loopComplete', () => {
      loaderAnimation.destroy();
    });
  }, []);

  return <div id="koalafi-initial-loader-ui" data-testid="initial-loader" />;
};

export default KoalafiInitialLoader;
