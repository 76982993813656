/* istanbul ignore file */

enum colors {
  darkBlue = '#0d4066',

  koalafiBlue = '#3d6edf',
  koalafiTurquoise = '#01febd',

  lightBlue = '#EFF7FF',

  darkGray = '#53565a',
  gray = '#6D6E71',
  middleGrey = '#747474',
  darkMidGray = '#a3a3a3',
  darkerLightGray = '#bfbfbf',
  lightGrey = '#DDDDDD',
  lighterLightGray = '#F2F5F5',

  white = '#ffffff',

  // Colors that can be combined into 1 or replaced with new UI
  almostBlack = '#2d2926',
  black = '#000000',

  red = '#FF0000',
  redOrange = '#e44f43',
  errorRed = '#eb4034',

  // Colors that I think can be deleted
  offWhiteBlue = '#EBF9FF',
  teal = '#2aa5de',
  purple = '#2b2f67',
}

enum fontFamilies {
  gilroyRegular = 'Gilroy-Regular, arial, sans-serif',
  gilroySemiBold = 'Gilroy-SemiBold, Gilroy-Regular, arial, sans-serif',
  gilroyBold = 'Gilroy-Bold, Gilroy-Regular, arial, sans-serif',
  gilroyExtraBold = 'Gilroy-ExtraBold, Gilroy-Regular, arial, sans-serif',
  interLight = 'Inter-Light, Inter-Regular',
  interRegular = 'Inter-Regular',
  interSemiBold = 'Inter-SemiBold, Inter-Regular',
  interBold = 'Inter-Bold, Inter-Regular',
}

enum fontSizes {
  regular = '1.125rem',
  mobile = '1rem',
}

enum breakpoints {
  sm = '640px',
  md = '768px',
  lg = '1024px',
  // TODO: ERK these all need to be reworked...
  // Showing UI as modal until 475px
  // The screen width is 425px
  modal = '425px',
}

export { colors, fontFamilies, fontSizes, breakpoints };
