import React, { useEffect } from 'react';
import Message from '../../components/message/Message';
import { usePage } from '../../hooks/segment';
type ErrorsFoundProps = {
  body: string;
  buttonText: string;
  lowerHeading?: string;
  buttonBehavior(): void;
  header?: string;
};

const ErrorsFound: React.FC<ErrorsFoundProps> = (props) => {
  const page = usePage();
  useEffect(() => {
    page('Errors Found');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Message
      heading={props.header || 'Hmm...'}
      lowerHeading={props.lowerHeading}
      body={props.body}
      buttonText={props.buttonText}
      buttonClick={props.buttonBehavior}
    />
  );
};

export default ErrorsFound;
