import { gql } from '@apollo/client';
import { apolloQuery, apolloMutation } from '../apollo';
import { SelectLoanOptionInput, SignLoanAgreementInput, AutopayInput } from '../gql/graphql';

export interface Loans {
  getLoanDocs(id: string): Promise<object>;
  getBankName(id: string): Promise<object>;
  selectLoanOptionType(input: SelectLoanOptionInput): Promise<object>;
  signLoanAgreement(input: SignLoanAgreementInput): Promise<object>;
  enrollInAutopay(input: any): Promise<object>;
}

const loansService: Loans = {
  async getLoanDocs(orderId: string) {
    return apolloQuery(generateLoanContract, { orderId });
  },
  async getBankName(routingNumber: string) {
    return apolloQuery(verifyRoutingQuery, { routingNumber });
  },
  async selectLoanOptionType(input: SelectLoanOptionInput) {
    return apolloMutation(selectLoanOptionType, { input: input });
  },
  async signLoanAgreement(input: SignLoanAgreementInput) {
    return apolloMutation(signLoanAgreement, { input: input });
  },
  async enrollInAutopay(input: AutopayInput) {
    return apolloMutation(enrollInAutopay, { input: input });
  },
};

export const enrollInAutopay = gql`
  mutation enrollInAutopay($input: AutopayInput!) {
    enrollInAutopay(input: $input) {
      autopayErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
      }
    }
  }
`;

export const signLoanAgreement = gql`
  mutation signLoanAgreement($input: SignLoanAgreementInput!) {
    signLoanAgreement(input: $input) {
      signingErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
        ... on NotSigned {
          message
        }
      }
    }
  }
`;

const generateLoanContract = gql`
  mutation generateLoanContract($orderId: String!) {
    generateLoanContract(orderId: $orderId) {
      loanDocuments {
        loanAgreement
        tila
      }
      loanDocumentsErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
      }
    }
  }
`;

const verifyRoutingQuery = gql`
  query verifyRouting($routingNumber: String!) {
    verifyRouting(routingNumber: $routingNumber) {
      bankName
      verifyRoutingErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
        ... on NotFound {
          message
        }
      }
    }
  }
`;

const selectLoanOptionType = gql`
  mutation selectLoanOptionType($input: SelectLoanOptionInput!) {
    selectLoanOptionType(input: $input) {
      loanSummary {
        id
        finalPaymentAmount
        paymentAmount
        firstPaymentDue
        annualPercentageRate
        financeCharge
        termLength
        amountFinanced
        loanAmount
        deferredInterestPeriod
      }
      loanOptionErrors {
        __typename
      }
    }
  }
`;

export default loansService;
