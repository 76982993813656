import { EventObject, Typestate } from 'xstate';
import { CustomerEligibility, Order } from '../../gql/graphql';

// Context Types
export type CustomerLookupMachineContext = {
  order: Order | null;
  customerFlowType: null | string;
  wasCodeValidated: boolean;
  isCurrentApplyTypeOTB: boolean;
  skipToPage?: string | null;
  isRedirecting: boolean;
  eligibilityDetails: CustomerEligibility | null;
  highestSupportedApplicationMilestone: number | undefined | null;
};

// State Types
export enum CustomerLookupStates {
  OTPVerification = 'OTPVerification',
  OTPRequest = 'OTPRequest',
  OTPCheck = 'OTPCheck',
  CustomerSearch = 'CustomerSearch',
  Loading = 'Loading',
  NewApplication = 'NewApplication',
  OTB = 'OTB',
  Last4SSN = 'Last4SSN',
  LongFormApplication = 'LongFormApplication',
  CustomerIdentification = 'CustomerIdentification',
  TermsAndConditions = 'TermsAndConditions',
  AuthorizedUserTerms = 'AuthorizedUserTerms',
  ExistingApplication = 'ExistingApplication',
  BasicInfo = 'BasicInfo',
  NoAccountFound = 'NoAccountFound',
  BasicsContinued = 'BasicsContinued',
  Decline = 'Decline',
  CustLookupInitialState = 'CustLookupIntialState',
}

export interface CustomerLookupMachineStatesSchema {
  [CustomerLookupStates.OTPVerification]: {};
  [CustomerLookupStates.OTPRequest]: {};
  [CustomerLookupStates.OTPCheck]: {};
  [CustomerLookupStates.CustomerSearch]: {};
  [CustomerLookupStates.Loading]: {};
  [CustomerLookupStates.NewApplication]: {};
  [CustomerLookupStates.OTB]: {};
  [CustomerLookupStates.Last4SSN]: {};
  [CustomerLookupStates.LongFormApplication]: {};
  [CustomerLookupStates.CustomerIdentification]: {};
  [CustomerLookupStates.TermsAndConditions]: {};
  [CustomerLookupStates.AuthorizedUserTerms]: {};
  [CustomerLookupStates.ExistingApplication]: {};
  [CustomerLookupStates.BasicInfo]: {};
  [CustomerLookupStates.NoAccountFound]: {};
  [CustomerLookupStates.BasicsContinued]: {};
  [CustomerLookupStates.Decline]: {};
}

export interface CustomerLookupMachineStates extends Typestate<CustomerLookupMachineContext> {
  type: CustomerLookupMachineStatesSchema;
}

// Event Types
export enum CustomerLookupEvents {
  NEXT = 'NEXT',
  OTP_NEXT = 'OTPNext',
  BASIC_INFO = 'BasicInfo',
  RESEND = 'Resend',
  CUSTOMER_FOUND = 'CustomerFound',
  LAST4SSN = 'Last4SSN',
  OTB = 'OTB',
  DECLINE = 'Decline',
  APPLICATION_STATUS = 'ApplicationStatus',
  CUSTOMER_NOTFOUND = 'CustomerNotFound',
  NOT_RECOGNIZED = 'NotRecognized',
  VALIDATION_ERROR = 'ValidationError',
  TERMS_AND_CONDITIONS = 'TermsAndConditionsButton',
  CUST_ID_PROGRAM = 'CustomerIdentificationButton',
  AUTH_USER_TERMS = 'AuthorizedUserTermsButton',
  BACK = 'BACK',
  NEW_APPLICATION = 'NewApplication',
  REQUEST_OTP = 'REQUEST_OTP',
  CHECK_OTP = 'CHECK_OTP',
  OTB_APPLICATION = 'OTB_APPLICATION',
}

type CustomerLookupEventsTypeSchema =
  | CustomerLookupEvents.NEW_APPLICATION
  | CustomerLookupEvents.NEXT
  | CustomerLookupEvents.OTP_NEXT
  | CustomerLookupEvents.BASIC_INFO
  | CustomerLookupEvents.RESEND
  | CustomerLookupEvents.CUSTOMER_FOUND
  | CustomerLookupEvents.LAST4SSN
  | CustomerLookupEvents.OTB
  | CustomerLookupEvents.DECLINE
  | CustomerLookupEvents.APPLICATION_STATUS
  | CustomerLookupEvents.CUSTOMER_NOTFOUND
  | CustomerLookupEvents.NOT_RECOGNIZED
  | CustomerLookupEvents.VALIDATION_ERROR
  | CustomerLookupEvents.TERMS_AND_CONDITIONS
  | CustomerLookupEvents.CUST_ID_PROGRAM
  | CustomerLookupEvents.AUTH_USER_TERMS
  | CustomerLookupEvents.BACK
  | CustomerLookupEvents.REQUEST_OTP
  | CustomerLookupEvents.CHECK_OTP
  | CustomerLookupEvents.OTB_APPLICATION;

export interface CustomerLookupMachineEvents extends EventObject {
  type: CustomerLookupEventsTypeSchema;
  data?: any;
}

// Guard Types
export enum CustomerLookupGuardsType {
  EXISTING_CUSTOMER_FOUND = 'ExistingCustomerFound',
  CUSTOMER_OTB = 'CustomerOTB',
  CUSTOMER_DECLINED = 'CustomerDeclined',
  CUSTOMER_NOT_FOUND = 'CustomerNotFound',
  SKIP_TO_PAGE = 'SkipToPage',
  NORMAL_INITIAL = 'NormalInitial',
}
type CustomerLookupGuardsTypeSchema =
  | CustomerLookupGuardsType.EXISTING_CUSTOMER_FOUND
  | CustomerLookupGuardsType.CUSTOMER_OTB
  | CustomerLookupGuardsType.CUSTOMER_NOT_FOUND
  | CustomerLookupGuardsType.CUSTOMER_DECLINED
  | CustomerLookupGuardsType.NORMAL_INITIAL
  | CustomerLookupGuardsType.SKIP_TO_PAGE;

export interface CustomerLookupMachineGuards extends EventObject {
  type: CustomerLookupGuardsTypeSchema;
}
