export const PaymentFrequencyOptions = [
  {
    displayName: 'Weekly',
    value: 'weekly',
  },
  {
    displayName: 'Every two weeks',
    value: 'biweekly',
  },
  {
    displayName: 'Twice per month',
    value: 'semimonthly',
  },
  {
    displayName: 'Monthly',
    value: 'monthly',
  },
];

export default PaymentFrequencyOptions;
