/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../baseStyle/Variables';

interface ModalProps {
  children: React.ReactNode;
}

const ModalCentering = styled.div`
  display: flex;
  padding: 0;
  width: 100%;
`;

const ModalStyle = styled.div`
  background-color: ${colors.white};
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
  position: relative;

  @media (min-width: 480px) {
    margin: 90px auto 48px;
    min-height: 810px;
    width: 425px;
  }
`;

const Modal: React.FC<ModalProps> = ({ children }) => {
  return (
    <ModalCentering>
      <ModalStyle>{children}</ModalStyle>
    </ModalCentering>
  );
};

export default Modal;
