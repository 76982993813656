import React, { useEffect, useState, Suspense } from 'react';
import styled from '@emotion/styled';
import { useStoreActions, useStoreState } from '../../hooks/easyPeasyHooks';
import { AppMode } from '../../stores/order.store';
import { convertToCurrency } from '../../helpers/converters/converters';
import { selectLeaseHelper } from '../../helpers';
import { usePage, useTrack } from '../../hooks/segment';
import { useSessionProperies } from '../../hooks/dtrum';
import { LeaseOptions } from '../../gql/graphql';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';
import LongLoader from '../../components/longLoader/LongLoader';
import Button from '../../components/button/Button';
import WallyPreApproval from '../../components/wally_pre_approval';
import ErrorsFound from '../errorsFound/ErrorsFound';

export interface PreApprovalProps {
  onApproval: any;
  onDeclineOrError: any;
}

const ApprovalContainer = styled.div`
  text-align: left;

  strong {
    font-family: ${fontFamilies.interSemiBold};
    font-weight: 900;
  }
`;

const Gradient = styled.div`
  width: 100%;
  max-width: 465px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
  margin-top: 1.5rem;
  height: 50px;
`;

const BottomContent = styled.div`
  margin-top: 3rem;
`;

const ButtonFaqWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    margin-bottom: 0.5rem;
  }

  p,
  a {
    font-size: 0.65rem;
  }
`;

const PaymentTermsDisclosure = styled.span`
  display: flex;
  width: 100%;
  font-family: ${fontFamilies.interRegular};
  font-size: 0.75rem;
  margin: 0;
  line-height: 1rem;
  color: ${colors.darkBlue};
`;

const PreApproval: React.FC<PreApprovalProps> = ({ onApproval, onDeclineOrError }) => {
  const {
    application,
    wasCardAuthorized,
    authorizeCardResponse,
    error: generalError,
  } = useStoreState((state) => state.applicationForm);
  const applicationId = useStoreState((state) => state.order.order?.application?.lease?.id);
  const { orderId, order } = useStoreState((state) => state.order);
  const dealerId = useStoreState((state) => state.order.order?.dealer.id);
  useStoreActions((actions) => actions.order);
  const { authorizeCard, setErrorGeneral } = useStoreActions((actions) => actions.applicationForm);
  useStoreState((state) => state.featureFlags);
  const track = useTrack();
  const page = usePage();
  const dtrumProps = useSessionProperies();
  const [pageAppMode, setPageAppMode] = useState<AppMode | null>();
  const [financingAmount, setFinancingAmount] = useState<string>();
  const [appFee, setAppFee] = useState<string>();
  const [shouldRedirect, setShouldRedirect] = useState<boolean>();

  useEffect(() => {
    setPageAppMode(AppMode.lease);
    const leaseOptions = (application?.lease?.options ?? order?.application?.lease?.options) as [LeaseOptions];
    let leaseTerm = selectLeaseHelper(leaseOptions);
    if (leaseTerm) {
      setFinancingAmount(leaseTerm.approvedAmount);
      setAppFee(leaseTerm?.applicationFee);
      page('Pre-Approval', { orderId, dealerId, approvalAmount: leaseTerm?.approvedAmount });
      track('Lease Pre-approved', { approvalAmount: financingAmount });
    }

    dtrumProps({
      appmode: pageAppMode as string,
    });
  }, [
    application?.lease?.options,
    dealerId,
    dtrumProps,
    financingAmount,
    order?.application?.lease?.options,
    orderId,
    page,
    pageAppMode,
    track,
  ]);

  const handleButtonClick = () => {
    if (applicationId != null) {
      track('Pre-Approval Continue', {
        approvalAmount: financingAmount,
      });

      setErrorGeneral(null);

      authorizeCard({
        applicationId: applicationId,
      });
      setShouldRedirect(true);
    }
  };

  useEffect(() => {
    if (shouldRedirect && (wasCardAuthorized || authorizeCardResponse.authorizeCardErrors.length > 0)) {
      if (authorizeCardResponse.authorizeCardErrors.length > 0) {
        if (authorizeCardResponse.authorizeCardErrors.some((err) => err.__typename === 'HardDecline')) {
          track('Debit Card Error', { errType: 'HardDecline' });
          onDeclineOrError(order);
          return;
        } else {
          track('Debit Card Error', { errType: 'SoftDecline' });
          onDeclineOrError(order);
          return;
        }
      }

      onApproval(order);
    }
  }, [
    authorizeCardResponse.authorizeCardErrors,
    onApproval,
    onDeclineOrError,
    shouldRedirect,
    order,
    track,
    wasCardAuthorized,
  ]);

  if (shouldRedirect && generalError) {
    track('Application Error', {
      errorType: 'generalError',
      error: generalError,
    });

    return (
      <ErrorsFound
        header="We're having some trouble"
        body="We were unable to process your application. Please resubmit below."
        buttonText="Resubmit application"
        buttonBehavior={() => {
          setShouldRedirect(false);
        }}
      />
    );
  }

  return shouldRedirect ? (
    <LongLoader message="We're reviewing your application" />
  ) : (
    <ApprovalContainer>
      <Suspense fallback={<LongLoader message="We're reviewing your application" />}>
        <WallyPreApproval financingAmount={financingAmount} />
        <Gradient />
        <BottomContent>
          <>
            <h5>Just one last step to finalize your approval!</h5>
            <h6>
              We’ll place a hold of {convertToCurrency(appFee ?? 0)} on your debit card to finalize your approval
              amount. We'll only charge it (plus tax) if you sign your lease agreement.
            </h6>
            <PaymentTermsDisclosure>
              Wondering about payment terms? You'll be shown all payment details before signing, and if you don't sign
              your lease, the authorization hold will be removed and you will not be charged.
            </PaymentTermsDisclosure>
          </>
          <ButtonFaqWrapper>
            <Button onClick={handleButtonClick}>Finalize Approval</Button>
          </ButtonFaqWrapper>
        </BottomContent>
      </Suspense>
    </ApprovalContainer>
  );
};

export default PreApproval;
