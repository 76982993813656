import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, ServerError, TypedDocumentNode } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AppConfig } from '../app.config';
import { generateCorrelationId } from '../helpers/logging/logging-helper';
import { inIframe } from '../helpers';

const httpLink = new HttpLink({
  uri: AppConfig.edgeServiceUrl,
});
const setContextHeaders = new ApolloLink((operation, forward) => {
  // Retrieve publicDealerId from query params (full page method)
  let publicDealerId = new URLSearchParams(document.location.search.substring(1)).get('dealerPublicId');

  // if publicDealerId not in query params, attempt to grab it from session storage (modal method)
  if (!publicDealerId) {
    publicDealerId = sessionStorage.getItem('publicDealerId');
  }
  const sessionId = sessionStorage.getItem('sessionId');

  let headers: Object = {
    'public-dealer-id': publicDealerId || ``,
    'Session-ID': sessionId || ``,
    'Correlation-ID': generateCorrelationId(),
    'x-in-iframe': inIframe(),
  };

  // check if unifiedSecurityToken is set in session storage,
  // this is only needed as a work around for safari users using our modal as safari blocks 3rd party cookies,
  // this logic can be removed when safari implements the 'Partitioned' cookie attribute
  const unifiedSecurityToken = sessionStorage.getItem('unifiedSecurityToken');
  if (unifiedSecurityToken) {
    headers = {
      ...headers,
      'x-unified-security-token': unifiedSecurityToken,
    };
  }

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers,
    credentials: 'include',
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

// check if unifiedSecurityToken header is returned in response and set in session storage,
// this is only needed as a work around for safari users using our modal as safari blocks 3rd party cookies,
// this logic can be removed when safari implements the 'Partitioned' cookie attribute
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();

    if (headers) {
      const token = headers.get('x-unified-security-token');

      if (token) {
        if (token === 'expired') {
          sessionStorage.removeItem('unifiedSecurityToken');
        } else {
          sessionStorage.setItem('unifiedSecurityToken', token);
        }
      }
    }

    return response;
  });
});

// check if unifiedSecurityToken header is returned in response and clear session storage if token is set as 'delete',
// this is only needed as a work around for safari users using our modal as safari blocks 3rd party cookies,
// this logic can be removed when safari implements the 'Partitioned' cookie attribute
const clearTokenLink = onError(({ operation, networkError }) => {
  let networkErr: ServerError = networkError as ServerError;
  if (networkErr?.statusCode === 401 || networkErr?.statusCode === 403) {
    const {
      response: { headers },
    } = operation.getContext();
    const token = headers.get('x-unified-security-token');

    if (token === 'expired') {
      sessionStorage.removeItem('unifiedSecurityToken');
    }
  }
});

const client = new ApolloClient({
  link: clearTokenLink.concat(afterwareLink.concat(setContextHeaders.concat(httpLink))),
  credentials: 'include', // needed in the root client
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export const apolloQuery = async (queryDoc: TypedDocumentNode, payload?: object) => {
  return client.query({
    query: queryDoc,
    variables: payload,
  });
};

export const apolloMutation = async (mutationDoc: TypedDocumentNode, payload?: object, context?: object) => {
  // Want to generate correlation id here + send to api
  return client.mutate({
    mutation: mutationDoc,
    variables: payload,
    ...(context && context),
  });
};

export default client;
