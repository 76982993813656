import { gql } from '@apollo/client';
import { apolloMutation } from '../apollo';
import { CheckVerificationTokenInput, VerifyHybridTokenInput } from '../gql/graphql';

export interface Auth {
  sendVerificationToken(cellPhone: string): Promise<object>;
  checkVerificationToken(input: CheckVerificationTokenInput): Promise<object>;
  verifyHybridToken(input: VerifyHybridTokenInput): Promise<object>;
}

const authService: Auth = {
  async sendVerificationToken(cellPhone: string) {
    return apolloMutation(SEND_VERIFICATION_TOKEN, { cellPhone: cellPhone });
  },
  async checkVerificationToken(input: CheckVerificationTokenInput) {
    return apolloMutation(CHECK_VERIFICATION_TOKEN, { input: input });
  },
  async verifyHybridToken(input: VerifyHybridTokenInput) {
    return apolloMutation(VERIFY_HYBRID_TOKEN, { input: input });
  },
};

export const SEND_VERIFICATION_TOKEN = gql`
  mutation sendVerificationToken($cellPhone: SendVerificationTokenInput!) {
    sendVerificationToken(input: $cellPhone) {
      status
      sendTokenErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on SMSNotSent {
          message
        }
      }
    }
  }
`;

export const CHECK_VERIFICATION_TOKEN = gql`
  mutation checkVerificationToken($input: CheckVerificationTokenInput!) {
    checkVerificationToken(input: $input) {
      checkTokenErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on MaxAttempts {
          message
        }
      }
    }
  }
`;

export const VERIFY_HYBRID_TOKEN = gql`
  mutation verifyHybridToken($input: VerifyHybridTokenInput!) {
    verifyHybridToken(input: $input) {
      verifyHybridTokenErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
      }
    }
  }
`;

export default authService;
