import React, { useCallback, useMemo } from 'react';
import { useStoreState, useStoreActions } from '../../hooks/easyPeasyHooks';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { convertToCurrency } from '../../helpers/converters/converters';
import { usePage, useTrack } from '../../hooks/segment';
import { selectLeaseHelper } from '../../helpers';
import ContractSummaryTable from './ContractSummaryTable';
import EarlyPurchaseContent from './EarlyPurchaseContent';
import MonthlyPaymentContent from '../../components/contract-summary-parts/monthly_payment';
import FineText from './FineText';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';
import LongLoader from '../../components/longLoader/LongLoader';
import CartValidation from '../../components/cartValidation/CartValidation';
import FormWrapper from '../../components/formWrapper/FormWrapper';
import PaymentMethod from '../../components/paymentMethod/PaymentMethod';
import { Checkbox } from '../../components/formElements/FormElements';
import Button from '../../components/button/Button';
import Link from '../../components/Link';
import RadioButtonCard from '../../components/radioButtonCard/RadioButtonCard';
import { Field, Form, Formik } from 'formik';
import { getLowestMinimumSpend } from '../../helpers/leases/leases.helpers';
import InternalError from '../internalError/InternalError';

type ContractSummaryProps = {
  onNext: () => void;
  onBack: () => void;
  onDebitEdit?: () => void;
};

const ContractSummaryContainer = styled.div`
  margin-top: 1rem;
  font-family: ${fontFamilies.interLight};
  h2 {
    margin-bottom: 0.5rem;
  }
`;

const CreditPullAuthStyle = styled.div`
  display: inline-block;
  margin-top: 0px !important;
  & a {
    text-decoration: none;
    cursor: pointer;
    font-family: ${fontFamilies.gilroySemiBold};
    font-size: 1rem !important;
    color: ${colors.koalafiBlue};
  }
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  line-height: 0px !important;
  & input {
    margin-bottom: 2.4rem;
    font-size: 1rem;
  }
`;

const CheckboxLabel = styled.div`
  margin-left: 0.25rem;
  font-size: 0.8rem !important;
  line-height: 125% !important;
  color: ${colors.darkBlue};
`;

const ButtonWrap = styled.div`
  & button {
    font-family: ${fontFamilies.gilroySemiBold};
    font-size: 1rem;
    font-weight: normal;
  }
`;

const Spacer = styled.div`
  height: 1rem;
`;

type GoBackButtonProps = {
  disabled?: boolean;
};
type OptionSummaryWrapperProps = {
  multiOptions?: boolean;
};

const GoBackButton = styled.div<GoBackButtonProps>`
  color: ${(props) => (props.disabled ? colors.darkerLightGray : '#3d6edf')};
  font-weight: bold;
  cursor: pointer;
`;

const GoBackWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
`;

const SmallSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: pre;

  font-size: 0.875rem;
`;

const LargeSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: pre;

  font-size: 1.25rem;
`;

const LabelWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const OptionSummaryWrapper = styled.div<OptionSummaryWrapperProps>`
  box-shadow: ${(props) => (props.multiOptions ? `0px 2px 8px 0px ${colors.lightGrey}` : '')};
  border-radius: ${(props) => (props.multiOptions ? '1rem' : '')};
  padding: ${(props) => (props.multiOptions ? '0.75rem' : '')};
`;

const paymentFrequencyLabels: { [index: string]: string } = {
  weekly: 'week',
  biweekly: 'two weeks',
  monthly: 'month',
  semimonthly: 'twice per month',
};

const ContractSummary: React.FC<ContractSummaryProps> = ({ onNext, onBack, onDebitEdit }) => {
  const { order, orderId } = useStoreState((state) => state.order);
  const { approvalAmount } = useStoreState((state) => state.virtualCard);
  const dealerId = useStoreState((state) => state?.order?.order?.dealer?.id);
  const orderHasItems = useStoreState((state) => state.order.hasItems);
  const virtualCardHasItems = useStoreState((state) => state.virtualCard.hasItems);
  const { queryOrder } = useStoreActions((state) => state.order);
  const { addToCart } = useStoreActions((state) => state.virtualCard);
  const applicationId = useStoreState((state) => state.order.order?.application?.lease?.id);
  const { selectLeaseOption } = useStoreActions((actions) => actions.applicationForm);
  const { selectedLeaseOption, wasLeaseOptionSelected, selectLeaseOptionErrors } = useStoreState(
    (state) => state.applicationForm,
  );
  const lowestMinimumSpend = getLowestMinimumSpend(order?.application?.lease?.options);

  const application = order?.application;

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [checked, setChecked] = useState(false);

  const areMultipleOptionsAvailable = (order?.application?.lease?.contractSummaries?.length ?? 0) > 1;

  const [selectedLeaseTerm, setSelectedLeaseTerm] = useState<string | undefined>();

  useEffect(() => {
    const shortestLeaseTerm = order?.application?.lease?.contractSummaries?.sort((prevVal, curVal) => {
      return Number(prevVal.leaseTerm) - Number(curVal.leaseTerm);
    })?.[0]?.leaseTerm;

    const previouslySelectedSummary = order?.application?.lease?.contractSummaries?.find(
      (summary: { leaseTerm: any }) => summary.leaseTerm === selectedLeaseOption?.leaseTerm,
    );

    setSelectedLeaseTerm(!!previouslySelectedSummary ? selectedLeaseOption?.leaseTerm : shortestLeaseTerm);
  }, [selectedLeaseOption, order?.application?.lease?.contractSummaries]);

  const track = useTrack();
  const page = usePage();

  useEffect(() => {
    page('Contract Summary', { dealerId, orderId });
    track('Contract Summary Viewed');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Handles resuming virtual card apps that are "DocsReady". Before a user is allowed to go back and edit their
  // cart, we have to know what items were in it. If there are no items in local state, we fetch the order from
  // AES to get the items from there. The user will then be able to click the button to go back and edit their cart
  // if desired.
  useEffect(() => {
    if (order?.dealer?.isVirtualCard && !virtualCardHasItems && orderId) {
      queryOrder(orderId);
    } else if (!order?.application?.lease?.contractSummaries && orderId) {
      queryOrder(orderId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (shouldRedirect && wasLeaseOptionSelected) {
    onNext();
  }

  useEffect(() => {
    if (selectLeaseOptionErrors?.length) {
      track('Selecting Lease Option Error');
    }
  }, [selectLeaseOptionErrors?.length, track]);

  // Need to deal with this.  Not sure what to do because we don't want the back button under the regular button?  Or do we?
  const handleUpdateCart = useCallback(
    (canEditCart: boolean) => {
      if (canEditCart) {
        if (!virtualCardHasItems) {
          order?.details?.items?.forEach((item: { displayName: string; quantity: number; price: string }) => {
            if (item.displayName !== 'Other Charges') {
              addToCart({
                itemName: item.displayName,
                quantity: item.quantity,
                price: (Math.round(parseFloat(item.price) * 100) * item.quantity) / 100,
              });
            }
          });
        }
        onBack();
        // history.push(`/flow/cart-summary?orderId=${orderId}`);
      }
    },
    [virtualCardHasItems, order?.details?.items, addToCart, onBack],
  );

  const handleSubmit = useCallback(() => {
    track('Contract Summary Acknowledged');
    if (applicationId && selectedLeaseTerm) {
      selectLeaseOption({
        applicationId: applicationId,
        leaseTerm: selectedLeaseTerm,
      });
      setShouldRedirect(true);
    }
  }, [track, applicationId, selectLeaseOption, selectedLeaseTerm]);

  // If contract details is undefined, we don't want to show this table at all
  const leaseApprovalAmount = selectLeaseHelper(order?.application?.lease?.options!)?.approvedAmount;

  const canEditCart = useMemo(() => {
    return (virtualCardHasItems || orderHasItems) && !!approvalAmount;
  }, [virtualCardHasItems, orderHasItems, approvalAmount]);

  const leaseOptionCards = useMemo(() => {
    if (!order?.application?.lease?.contractSummaries) {
      return null;
    }

    return order.application.lease.contractSummaries
      .sort((a: { leaseTerm: string }, b: { leaseTerm: string }) => {
        return Number(a.leaseTerm) - Number(b.leaseTerm);
      })
      .map((contractSummary: any) => (
        <React.Fragment key={`${contractSummary.leaseTerm}`}>
          <Field
            id={`contractSummaryLeaseOptionSelector${contractSummary.leaseTerm}`}
            name={`contractSummaryLeaseOptionSelector`}
            radioValue={contractSummary.leaseTerm}
            radioLabel={
              <LabelWrap>
                <LargeSpan>{contractSummary.leaseTerm}</LargeSpan>
                <SmallSpan> months</SmallSpan>
              </LabelWrap>
            }
            cardContent={
              <LabelWrap>
                <LargeSpan>{convertToCurrency(contractSummary.scheduledPaymentAmounts[2])}</LargeSpan>
                <SmallSpan> / {paymentFrequencyLabels[contractSummary.paymentFrequency]}</SmallSpan>
              </LabelWrap>
            }
            onChange={() => {}}
            component={RadioButtonCard}
            changeHandler={() => {
              setSelectedLeaseTerm(contractSummary.leaseTerm);
            }}
          />
        </React.Fragment>
      ));
  }, [order?.application?.lease?.contractSummaries]);

  const optionSummary = useMemo(() => {
    if (!order?.application?.lease?.contractSummaries || !selectedLeaseTerm) {
      return null;
    }

    const summary = order.application.lease.contractSummaries.find(
      (contractSummary: { leaseTerm: string | undefined }) => {
        return contractSummary.leaseTerm === selectedLeaseTerm;
      },
    );

    if (!summary) {
      return null;
    }

    const editedPayments = [...summary.scheduledPaymentAmounts];
    const secondPayment = editedPayments?.shift();
    const finalPayment = editedPayments?.pop();
    const numberOfSimilarPayments =
      secondPayment === finalPayment
        ? summary.scheduledPaymentAmounts.length + 1
        : summary.scheduledPaymentAmounts.length;

    // Item Price calculations
    let itemsTotal: number = 0.0;
    if (order?.details.items && order?.details.items.length > 0) {
      order?.details.items.forEach((item: { price: any; quantity: any }) => {
        itemsTotal = itemsTotal + Number(item.price) * Number(item.quantity);
      });
      itemsTotal = itemsTotal + +order?.details.shippingAmnt!;
    }

    const custState = order?.application?.customer?.billingAddress?.state;

    const paymentSummary = {
      eboDetails: {
        cashPlusFee: convertToCurrency(+summary.cashPlusFee!),
        cashPlusPrice: convertToCurrency(+summary.cashPlusPrice),
        cashPlusTerm: { singular: `${summary.cashPlusTerm} day`, plural: `${summary.cashPlusTerm} days` },
      },
      priceDetails: {
        itemsTotal: convertToCurrency(itemsTotal),
        salesTax: convertToCurrency(+summary.tax!),
        totalAmount: convertToCurrency(+summary.leaseAmount + Number(summary.tax)),
        totalWithTax: convertToCurrency(+itemsTotal + Number(summary.tax!)),
        initialPayment: convertToCurrency(+summary.initialPaymentAmount),
        costOfLease: convertToCurrency(+summary.totalCostOfOwnership - +summary.leaseAmount),
        showCostOfLease: custState === 'PA',
        costOfOwnership: convertToCurrency(+summary.totalCostOfOwnership),
        initialPaymentWithTax: convertToCurrency(+summary.initialPaymentWithTax),
      },
      paymentSchedule: {
        secondPayment: convertToCurrency(secondPayment!),
        finalPayment: convertToCurrency(finalPayment!),
        isFinalPaymentDifferent: !(secondPayment === finalPayment),
        paymentFrequency: summary.paymentFrequency,
        numSimilarPayments: numberOfSimilarPayments,
        numRemainingPayments: summary?.scheduledPaymentAmounts.length,
        timeToOwnership: `${summary?.leaseTerm} months`,
      },
    };

    return (
      <OptionSummaryWrapper multiOptions={!!areMultipleOptionsAvailable}>
        {areMultipleOptionsAvailable && (
          <>
            <h2>Contract Summary</h2>
            <div></div>
          </>
        )}
        <MonthlyPaymentContent
          monthlyPayment={areMultipleOptionsAvailable ? null : paymentSummary.paymentSchedule.secondPayment}
          payFrequency={paymentSummary.paymentSchedule.paymentFrequency}
        />
        <ContractSummaryTable
          isVirtualCard={!!order?.dealer?.isVirtualCard}
          retailPrice={paymentSummary.priceDetails.itemsTotal}
          tax={paymentSummary.priceDetails.salesTax}
          totalPurchaseAmount={paymentSummary.priceDetails.totalAmount}
          timeToOwnership={paymentSummary.paymentSchedule.timeToOwnership}
          paymentFrequency={paymentSummary.paymentSchedule.paymentFrequency}
          numRemainingPayments={paymentSummary.paymentSchedule.numRemainingPayments}
          initialPayment={paymentSummary.priceDetails.initialPaymentWithTax}
          secondPayment={paymentSummary.paymentSchedule.secondPayment}
          numSimilarPayments={paymentSummary.paymentSchedule.numSimilarPayments}
          finalPayment={
            paymentSummary.paymentSchedule.isFinalPaymentDifferent ? paymentSummary.paymentSchedule.finalPayment : null
          }
          costOfLease={paymentSummary.priceDetails.showCostOfLease ? paymentSummary.priceDetails.costOfLease : null}
          costOfOwnership={paymentSummary.priceDetails.costOfOwnership}
          cashPlusPrice={paymentSummary.eboDetails.cashPlusPrice}
          cashPlusTerm={paymentSummary.eboDetails.cashPlusTerm}
        />
        <EarlyPurchaseContent
          isVirtualCard={!!order?.dealer?.isVirtualCard}
          itemsWithTax={paymentSummary.priceDetails.totalWithTax}
          ipWithTax={paymentSummary.priceDetails.initialPaymentWithTax}
          cashPlusPrice={paymentSummary.eboDetails.cashPlusPrice}
          cashPlusFee={paymentSummary.eboDetails.cashPlusFee}
          cashPlusTerm={paymentSummary.eboDetails.cashPlusTerm}
          itemsTotal={paymentSummary.priceDetails.itemsTotal}
          salesTax={paymentSummary.priceDetails.salesTax}
        />

        <Spacer />
        <FineText />
        <Formik
          onSubmit={handleSubmit}
          initialValues={{}}
          validate={() => {}}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          <FormWrapper>
            <Form>
              {application?.lease?.isOtb && <PaymentMethod onDebitEdit={onDebitEdit} />}
              <CheckboxWrapper onClick={() => setChecked(!checked)}>
                <Checkbox
                  type="checkbox"
                  name="acknowledgement"
                  data-testid="acknowledgement-checkbox"
                  checked={checked}
                  onChange={() => {}}
                  size={1}
                />

                {/* For OTB we do not want to show the credit pull authorization */}
                {(!!application?.lease?.isOtb || !!application?.loan?.isOtb) && (
                  <CheckboxLabel>I acknowledge the contract summary</CheckboxLabel>
                )}
                {!application?.lease?.isOtb && !application?.loan?.isOtb && (
                  <CheckboxLabel>
                    I acknowledge the contract summary and agree to the{' '}
                    <CreditPullAuthStyle>
                      <Link
                        onClick={() => window.open('https://apply.koalafi.com/credit-pull-auth')}
                        linkText="Credit Pull Authorization"
                      />
                    </CreditPullAuthStyle>
                  </CheckboxLabel>
                )}
              </CheckboxWrapper>
              <ButtonWrap>
                {/* @ts-ignore */}
                <Button type="submit" disabled={!checked} dataLoading={shouldRedirect}>
                  Next: Lease Agreement
                </Button>
              </ButtonWrap>
              {/* This shouldn't be there anymore, should be in the header */}
              {order?.dealer?.isVirtualCard && (
                <GoBackWrapper onClick={() => handleUpdateCart(canEditCart)}>
                  <GoBackButton disabled={!canEditCart}>Update cart</GoBackButton>
                </GoBackWrapper>
              )}
            </Form>
          </FormWrapper>
        </Formik>
      </OptionSummaryWrapper>
    );
  }, [
    order?.application?.customer?.billingAddress?.state,
    order?.application?.lease?.contractSummaries,
    order?.dealer?.isVirtualCard,
    order?.details.items,
    order?.details.shippingAmnt,
    shouldRedirect,
    application?.lease?.isOtb,
    application?.loan?.isOtb,
    selectedLeaseTerm,
    checked,
    canEditCart,
    areMultipleOptionsAvailable,
    handleSubmit,
    handleUpdateCart,
    onDebitEdit,
  ]);

  return (
    <CartValidation approvedAmount={leaseApprovalAmount} minimumSpend={lowestMinimumSpend}>
      <div data-testid="contract-summary-body" />
      {!order?.application?.lease?.contractSummaries ? (
        <LongLoader message="We're finalizing the details" />
      ) : (
        <>
          {selectLeaseOptionErrors?.length ? (
            <InternalError
              body={
                'We were unable to record your signature, but are working on resolving this ASAP. Please try again later.'
              }
            />
          ) : (
            <ContractSummaryContainer>
              <h2>
                {areMultipleOptionsAvailable ? 'Customize your payment schedule' : "Here's your contract summary"}
              </h2>
              <div></div>
              {areMultipleOptionsAvailable ? (
                <Formik
                  onSubmit={() => {}}
                  initialValues={{
                    contractSummaryLeaseOptionSelector: selectedLeaseTerm,
                  }}
                  validate={() => {}}
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                >
                  <Form>{leaseOptionCards}</Form>
                </Formik>
              ) : (
                <></>
              )}
              {optionSummary}
            </ContractSummaryContainer>
          )}
        </>
      )}
    </CartValidation>
  );
};

export default ContractSummary;
